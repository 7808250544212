import React from 'react'
import "./IncidentViewer.scss"
import {
    CircularProgress
} from "@mui/material";
import server from "../../services/server";
import {
    IColumnArray,
    IColumnObject,
} from "../../services/types/columns";
import {IIncidentReport} from "../../services/types/incident";
import {isEmpty} from "./dataTreatment";
import {expandSourcesHandler} from "./fields/BaseIncidentField";
import IncidentField from "./fields/IncidentField";

interface IProps {
    column?: IColumnObject,
    emptyChecker?: (v: any) => boolean,
    data: any,
    citation?: string,
    sources?: IIncidentReport[],
    onEdit?: ((value: any, field: string | null) => void),
    onClick?: ((value: any) => void),
    onSourcesClick?: expandSourcesHandler,
    labelOverride?: React.ReactElement
}

interface IState {
    column?: IColumnObject,
    emptyChecker?: (v: any) => boolean,
}

class IncidentEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            column: this.props.column,
            emptyChecker: this.props.emptyChecker
        }
    }

    async componentDidMount() {
        if (!this.state.column) {
            await this.fetchColumns()
        }
        if (!this.state.emptyChecker) {
            await this.fetchEmptyDataIndicators()
        }
    }

    private async fetchColumns() {
        const res = await server.get("columns/");
        if (res && !res.error) {
            const column = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
            this.setState((current) => ({...current, column}))
        }
    }

    async fetchEmptyDataIndicators() {
        const res = await server.get("null_data_flags/");
        const emptyDataIndicators = res.map((x: { flag: string }) => x.flag)
        this.setState((current) => ({
            ...current,
            emptyChecker: (v: any) => isEmpty(v, emptyDataIndicators)
        }))
    }

    render() {
        const column = this.state.column;
        const emptyChecker = this.state.emptyChecker;
        return (column && emptyChecker) ?
            <IncidentField
                column={column}
                data={this.props.data}
                citation={this.props.citation || null}
                sources={this.props.sources || null}
                onEdit={this.props.onEdit || null}
                onClick={this.props.onClick || null}
                onSourcesClick={this.props.onSourcesClick || null}
                emptyChecker={emptyChecker}
            /> : <CircularProgress/>
    }
}

export default IncidentEditor;