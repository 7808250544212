import React from 'react'
import ListPicker from "./ListPicker";
import {ListItemText} from "@mui/material";
import {getTextDirection} from "../../services/multiLangUI";
import {ISource} from "../../services/entities/sources";


interface IProps {
    data: ISource[],
    multiple: boolean,
    selected: ISource[],
    onSelect: (selected: ISource[]) => void,
}

interface IState {}

class SourcesPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        return <ListPicker
            data={this.props.data}
            id={"url"}
            multiple={this.props.multiple}
            onSelect={this.props.onSelect}
            renderer={(item) => {
                return <ListItemText
                    primary={item.title}
                    secondary={item.url}
                    dir={getTextDirection(item.title)}
                    sx={{
                        "& .MuiListItemText-primary": {
                            "direction": getTextDirection(item.title),
                            "textAlign": getTextDirection(item.title) === "rtl" ? "right" : "left"
                        }
                    }}
                />
            }}
            selected={this.props.selected}
        />
    }
}

export default SourcesPicker;