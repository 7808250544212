import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import React from "react";

export interface IExclusionKeyword extends DataItem {
    keyword: string;
    stemmed?: string;
    exact: boolean;
    regex: boolean;
}

export interface IExclusionKeywordsQuery extends DataQuery<IExclusionKeyword> {}

export class ExclusionKeyword extends Entity<IExclusionKeyword, IExclusionKeywordsQuery> {
    title = "Exclusion Keywords";
    endpoint = "keywords/exclusion";
    slug = "exclusion-keywords";
    fields: IDataField<IExclusionKeyword>[] = [
        {title: "keyword", key: "keyword", field_type: "str"},
        {title: "stemmed", key: "stemmed", field_type: "str", disabled: true},
        {title: "exact", key: "exact", field_type: "bool"},
        {title: "regex", key: "regex", field_type: "bool"},
    ];
    defaultNewItem = {
        keyword: "",
        stemmed: "",
        exact: false,
        regex: false
    };
    accordionHeader = (r: IExclusionKeyword) => {
        return <span>{r.keyword || "new keyword"}</span>
    };
}