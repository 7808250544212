import React from 'react'
import {CircularProgress, Stack} from "@mui/material";
import PostsPicker from "./PostsPicker";
import {IPost, IPostsQuery} from "../../services/entities/posts";
import {ENTITIES} from "../../services/entities/entities";


interface IProps {
    multiple: boolean,
    selected: IPost[],
    onSelect: (selected: IPost[]) => void,
    query: IPostsQuery
}

interface IState {
    loading: boolean,
    error?: string,
    posts: IPost[] | null,
}


class ExistingPostsPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            posts: null,
        }
    }

    async componentDidMount() {
        await this.query();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query)) {
            await this.query()
        }
    }

    private async query() {
        if (this.state.loading) {
            return
        }
        this.setState((curr) => ({
            ...curr, loading: true, error: undefined
        }), async () => {
            const res = await ENTITIES["POSTS"].query(this.props.query);
            if (res && !("error" in res)) {
                this.setState((curr) => ({
                    ...curr,
                    posts: res.rows,
                    loading: false,
                    error: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    posts: null,
                    loading: false,
                    error: res.error || "Unknown Error Occurred"
                }))
            }
        })
    }

    render() {
        return <Stack direction={"column"}>
            {this.state.loading ? <CircularProgress/> : null}
            {this.state.error ? <p>{this.state.error}</p> : null}
            {
                this.state.posts ?
                    <PostsPicker
                        data={
                            this.state.posts
                        }
                        multiple={this.props.multiple}
                        selected={this.props.selected}
                        onSelect={this.props.onSelect}
                    /> :
                    null
            }
        </Stack>
    }
}

export default ExistingPostsPicker;