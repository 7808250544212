import React, { Component } from 'react';
import { Button, Stack } from '@mui/material';
import WebsitePreviewer from '../../pages/sources/WebsitePreviewer';
import SourcesPreviewPostsPicker from '../../UIComponents/ListPicker/SourcesPreviewPostsPicker';
import {ISource} from "../../services/entities/sources";

interface IProps {
    data: ISource;
    setData: (data: ISource) => any;
}

interface IState {
    activated: boolean;
}

class SourcePreviewer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activated: false,
        };
    }

    activatePreviews = () => {
        this.setState({ activated: true });
    };

    render() {
        const { data, setData } = this.props;
        const { activated } = this.state;

        if (!activated) {
            return (
                <Button variant="contained" onClick={this.activatePreviews}>
                    Preview Frontpage Items
                </Button>
            );
        }

        return (
            <>
                {data.source_type === 'news_site' && data.url && (
                    <WebsitePreviewer
                        url={data.url}
                        load_in_browser={!!data.load_in_browser}
                        selectors={{
                            title_selector: data.title_selector,
                            body_selector: data.body_selector,
                            date_selector: data.date_selector,
                            article_entry_selector: data.article_entry_selector,
                        }}
                        setSelectors={(s) => {
                            const newSourceData = { ...data, ...s };
                            setData(newSourceData);
                        }}
                    />
                )}
                {data.source_type === 'telegram' && data.url && (
                    <SourcesPreviewPostsPicker
                        multiple={false}
                        selected={[]}
                        onSelect={() => {}}
                        url={data.url}
                        source_type={'telegram'}
                    />
                )}
            </>
        );
    }
}

export default SourcePreviewer;