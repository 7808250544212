import React from 'react'
import {CircularProgress, Stack} from "@mui/material";
import server from "../../services/server";
import PostsPicker from "./PostsPicker";
import {ISourceSelectors, TSourceType} from "../../services/entities/sources";
import {IPartialPost} from "../../services/entities/posts";


interface IProps {
    multiple: boolean,
    selected: IPartialPost[],
    onSelect: (selected: IPartialPost[]) => void,
    url: string | null,
    source_type: TSourceType,
    load_in_browser?: boolean,
    selectors?: ISourceSelectors
}

interface IState {
    loadingEntriesPreview: boolean,
    entriesPreviewError?: string,
    entriesPreview: IPartialPost[] | null,
}


class SourcesPreviewPostsPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingEntriesPreview: false,
            entriesPreview: null,
        }
    }

    async componentDidMount() {
        await this.previewSourceEntries()
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            prevProps.url !== this.props.url ||
            prevProps.load_in_browser !== this.props.load_in_browser ||
            prevProps?.selectors?.article_entry_selector !== this.props?.selectors?.article_entry_selector ||
            prevProps.source_type !== this.props.source_type
        ) {
            await this.previewSourceEntries()
        }
    }

    private async previewSourceEntries() {
        if (this.state.loadingEntriesPreview || !this.props.url) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingEntriesPreview: true, entriesPreviewError: undefined
        }), async () => {
            const res = await server.post("sources/preview/entries/", {
                url: this.props.url,
                load_in_browser: this.props.load_in_browser,
                article_entry_selector: this.props?.selectors?.article_entry_selector,
                source_type: this.props.source_type
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, entriesPreview: res,
                    loadingEntriesPreview: false,
                    entriesPreviewError: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    entriesPreview: null,
                    loadingEntriesPreview: false,
                    entriesPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    render() {
        return <Stack direction={"column"}>
            {this.state.loadingEntriesPreview ? <CircularProgress/> : null}
            {this.state.entriesPreviewError ? <p>{this.state.entriesPreviewError}</p> : null}
            {
                this.state.entriesPreview ?
                    <PostsPicker
                        data={
                            this.state.entriesPreview
                        }
                        multiple={this.props.multiple}
                        selected={this.props.selected}
                        onSelect={this.props.onSelect}
                    /> :
                    null
            }
        </Stack>
    }
}

export default SourcesPreviewPostsPicker;