import {TSourceType} from "./sources";
import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import React from "react";

export interface IPartialPost {
    url?: string;
    preview_title?: string;
    content?: string;
    source_type: TSourceType;
}

export interface IPost extends IPartialPost, DataItem {
    date_found?: Date;
    title?: string;
    publication_date?: Date;
    html?: string;
    relevancy?: "relevant" | "irrelevant" | "unchecked" | "awaiting_ai_screening" | "error";
    downloaded?: "downloaded" | "not downloaded" | "download failed";
    parsed?: "parsed" | "unparsed" | "parsing failed";
    analyzed?: "analyzed" | "not analyzed" | "analysis failed";
    download_date?: Date;
    parse_date?: Date;
    analysis_date?: Date;
    keyword_effect_log?: string;
    hide?: boolean;
    source_id?: number;
    source_title?: string;
}

export interface IPostsQuery extends DataQuery<IPost> {
}

export class Post extends Entity<IPost, IPostsQuery> {
    title = "Posts";
    endpoint = "posts";
    slug = "posts";
    fields: IDataField<IPost>[] = [
        {title: "ID", key: "id", field_type: "number", disabled: true},
        {title: "Source ID", key: "source_id", field_type: "number", disabled: true},
        {title: "URL", key: "url", field_type: "str"},
        {title: "Title", key: "title", field_type: "str"},
        {title: "Publication Date", key: "publication_date", field_type: "str", disabled: true},
        {title: "Content", key: "content", field_type: "str", multi_line: true},
        {
            title: "Relevancy", key: "relevancy", field_type: "select", options: [
                {value: "relevant", label: "Relevant"},
                {value: "irrelevant", label: "Irrelevant"},
                {value: "unchecked", label: "Unchecked"},
                {value: "awaiting_ai_screening", label: "Awaiting AI Screening"},
                {value: "error", label: "Error"}
            ]
        },
        {
            title: "Downloaded", key: "downloaded", field_type: "select", options: [
                {value: "downloaded", label: "Downloaded"},
                {value: "not downloaded", label: "Not Downloaded"},
                {value: "download failed", label: "Download Failed"}
            ]
        },
        {
            title: "Parsed", key: "parsed", field_type: "select", options: [
                {value: "parsed", label: "Parsed"},
                {value: "unparsed", label: "Unparsed"},
                {value: "parsing failed", label: "Parsing Failed"}
            ]
        },
        {
            title: "Analyzed", key: "analyzed", field_type: "select", options: [
                {value: "analyzed", label: "Analyzed"},
                {value: "not analyzed", label: "Not Analyzed"},
                {value: "analysis failed", label: "Analysis Failed"}
            ]
        },
        {title: "Found on", key: "date_found", field_type: "str", disabled: true},
        {title: "Downloaded on", key: "download_date", field_type: "str", disabled: true},
        {title: "Parsed on", key: "parse_date", field_type: "str", disabled: true},
        {title: "Analysed on", key: "analysis_date", field_type: "str", disabled: true},
    ];
    defaultNewItem: IPost = {
        source_type: "news_site",
        title: "",
        url: "",
        publication_date: new Date(),
        html: "",
        relevancy: "unchecked",
        downloaded: "not downloaded",
        parsed: "unparsed",
        analyzed: "not analyzed",
        download_date: new Date(),
        parse_date: new Date(),
        analysis_date: new Date(),
        keyword_effect_log: "",
        hide: false
    };
    accordionHeader = (r: IPost) => <div
        style={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxHeight: "3em",
            lineHeight: "1em",
            width: "100%"
        }}
    >{
        r.id ? (
            r.title ||
            r.preview_title ||
            r.content ||
            "Untitled Post (" + [
                r.source_title ? "Source: " + r.source_title : "Untitled Source",
                r.publication_date ? "Published: " + r.publication_date.toLocaleString().split("T")[0] :
                    r.date_found ? "Created: " + r.date_found.toLocaleString().split("T")[0] : "No date"
            ].join(" ") + ")"
        ) : "New Post"
    }</div>
}