import React from 'react';
import {Stack, Step, StepButton, Stepper} from '@mui/material';
import SourcesPreviewPostsPicker from './SourcesPreviewPostsPicker';
import {ISource, ISourcesQuery} from '../../services/entities/sources';
import {IPartialPost, IPost} from '../../services/entities/posts';
import QueryableExistingSourcesPicker from "./QueryableSourcesPicker";
import server from "../../services/server";

interface IProps {
    multiple: boolean,
    selected: IPost[],
    onSelect: (selected: IPost[]) => void,
    initQuery?: ISourcesQuery
}

interface IState {
    activeStep: number,
    selectedSource: null | ISource
}

class SourcePostPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activeStep: 0,
            selectedSource: null
        };
    }

    handleSourceSelect = (selected: ISource[]) => {
        this.setState({
            selectedSource: selected.length ? selected[0] : null,
            activeStep: selected.length ? 1 : 0
        });
        this.props.onSelect([])
    };


    render() {
        const {activeStep, selectedSource} = this.state;
        const selectionSteps: {
            label: string,
            disabled: boolean,
            completed: boolean
        }[] = [
            {label: 'Select Source', disabled: false, completed: !!selectedSource},
            {label: 'Select Post', disabled: !selectedSource, completed: activeStep > 1},
        ];

        return (
            <Stack
                spacing={4}
                sx={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "100%",
                    width: "100%"
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    sx={{marginTop: "1em !important"}}
                >
                    {selectionSteps.map((s, i) => (
                        <Step key={i} disabled={s.disabled} completed={s.completed}>
                            <StepButton
                                color="inherit"
                                onClick={() => {
                                    this.setState((curr) => ({...curr, activeStep: i}))
                                }}
                            >
                                {s.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === 0 && (
                        <QueryableExistingSourcesPicker
                            multiple={false}
                            selected={selectedSource ? [selectedSource] : []}
                            onSelect={this.handleSourceSelect}
                            initQuery={this.props.initQuery}
                        />
                    )}
                    {activeStep === 1 && selectedSource && (
                        <SourcesPreviewPostsPicker
                            url={selectedSource.url}
                            load_in_browser={selectedSource.load_in_browser || false}
                            selectors={{
                                article_entry_selector: selectedSource.article_entry_selector,
                                title_selector: selectedSource.title_selector,
                                body_selector: selectedSource.body_selector,
                                date_selector: selectedSource.date_selector,
                            }}
                            source_type={selectedSource.source_type}
                            multiple={false}
                            selected={this.props.selected}
                            onSelect={async (posts)=>{await this.getPostDetails(posts)}}
                        />
                    )}
                </div>
            </Stack>
        );
    }

    private async getPostDetails(partialPosts: IPartialPost[]) {
        // the Preview Posts Picker doesn't actually pick up all the relevant details about the post
        // so to make things clearer, switch it to use an IPartialPost interface, which IPost extends
        // and here, for each post selected which is not already in the selected list, fetch the full post details
        // then invoke this.props.onSelect with the updated list of fully-detailed posts

        const detailedPosts = (await Promise.all(partialPosts.map(async (p)=>{
            const existingEntry = this.props.selected.find(sp=>sp.url === p.url)
            if(existingEntry){
                return existingEntry
            }
            if(this.state.selectedSource?.source_type === "telegram"){
                return p as IPost
            }
            const postDetails = await server.post("posts/preview/", {
                url: p.url,
                source_type: p.source_type,
                load_in_browser: this.state.selectedSource?.load_in_browser,
                title_selector: this.state.selectedSource?.title_selector,
                body_selector: this.state.selectedSource?.body_selector,
                date_selector: this.state.selectedSource?.date_selector
            });
            if(postDetails && !postDetails.error){
                return postDetails as IPost
            }
            return null
        }))).filter(x=> x !== null) as IPost[];

        this.props.onSelect(detailedPosts);
    }
}

export default SourcePostPicker;