import {Source} from "./sources";
import {Post} from "./posts";
import {InclusionKeyword} from "./inclusionKeywords";
import {ExclusionKeyword} from "./exclusionKeywords";
import {NullDataFlag} from "./nullDataFlags";
import {Entity} from "./entityBase";
import {DataQuery} from "./entityFields";
import {E_ENTITIES} from "../types/entities";

export const ENTITIES: {[key in E_ENTITIES]: Entity<any, DataQuery<any>>} = {
    "POSTS": new Post(),
    "SOURCES": new Source(),
    "NULL_DATA_FLAGS": new NullDataFlag(),
    "INCLUSION_KEYWORDS": new InclusionKeyword(),
    "EXCLUSION_KEYWORDS": new ExclusionKeyword()
} as const;