import React from 'react'
import {Button, FormControlLabel, IconButton, Stack, Switch, TextField} from "@mui/material";
import SelfContainedPopover from "../SelfContainedComponents/selfContainedPopover";
import {FilterAlt, FilterList} from "@mui/icons-material";
import {ISource, ISourcesQuery} from "../../services/entities/sources";
import ExistingSourcesPicker from "./ExistingSourcesPicker";
import {ENTITIES} from "../../services/entities/entities";


interface IProps {
    initQuery?: ISourcesQuery
    multiple: boolean,
    selected: ISource[],
    onSelect: (selected: ISource[]) => void,
}

interface IState {
    query: ISourcesQuery
}


class QueryableExistingSourcesPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            query: this.props.initQuery || ENTITIES["SOURCES"].defaultQuery
        }
    }

    render() {
        return <Stack direction={"column"} gap={1}>
            <SelfContainedPopover
                trigger={(popupVisibilitySetter) => {
                    return <Stack direction={"row"} alignItems={"start"}>
                        <IconButton
                            color={"primary"}
                            onClick={(e) => popupVisibilitySetter(e, true)}
                        >
                            <FilterList/>
                        </IconButton>
                    </Stack>
                }}
                content={() => {
                    return <Stack direction={"column"} gap={1}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            onChange={(e) => {
                                const searchQuery = e.target.value;
                                this.setState((curr) => ({
                                    query: {
                                        ...curr.query,
                                        search: searchQuery,
                                    },
                                }));
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={(e) => {
                                        const onlyActive = e.target.checked;
                                        this.setState((curr) => ({
                                            query: {
                                                ...curr.query,
                                                is_active: onlyActive ? true : undefined,
                                            },
                                        }));
                                    }}
                                />
                            }
                            label="Only include active sources"
                        />
                    </Stack>
                }}
            />
            <ExistingSourcesPicker
                multiple={this.props.multiple}
                selected={this.props.selected}
                onSelect={this.props.onSelect}
                query={this.state.query}
            />
        </Stack>
    }
}

export default QueryableExistingSourcesPicker;