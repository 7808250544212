import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    LiteralUnion,
    MaterialReactTable,
    MRT_ColumnDef, MRT_ColumnFilterFnsState,
    MRT_ColumnFiltersState, MRT_ColumnOrderState, MRT_ColumnPinningState, MRT_ColumnSizingState, MRT_FilterOption,
    MRT_PaginationState, MRT_Row, MRT_RowData, MRT_RowSelectionState,
    MRT_SortingState,
    MRT_TableInstance,
    MRT_TableOptions,
    MRT_VisibilityState,
    useMaterialReactTable,
} from 'material-react-table';
import {IErrorResponse} from '../../services/server';
import {IColumnObject, IColumnSelect, TColumnTypes} from "../../services/types/columns";
import {getColDefaultWidth, incidentColFilters, incidentColRenderers} from "../incident_browser/columnRenderers";
import {
    dataFieldFilters,
    dataFieldRenderers,
    DataFieldType, DataQuery,
    getDataFieldDefaultWidth,
    IDataField, IDataFieldJSON, IDataFieldSelect, IQueryResult
} from "../../services/entities/entityFields";

const PRIMARY_ID_PREFIX = "_PRIMARY_ROW_ID_";
const DEFAULT_ITEMS_PER_PAGE = 10;

interface BaseTableProps<T extends MRT_RowData> {
    tableTitle: string,
    runQuery: (query: DataQuery<T>) => Promise<IQueryResult<T> | IErrorResponse>,
    schema: IDataField<T>[],
    initQuery?: DataQuery<T>,
    onQueryChange?: (query: DataQuery<T>) => any,
    renderTopToolbarCustomActions?: (tableProps: { table: MRT_TableInstance<T> }) => React.ReactNode;
    renderRowActions?: (rowProps: { row: MRT_Row<T> }) => React.ReactNode;
    tableOptionsOverrides?: Partial<MRT_TableOptions<T>>;
    permanentlySelectedRows?: number[],
    emptyChecker?: (value: any) => boolean,
}

const BaseTable = <T extends MRT_RowData>(
    {
        tableTitle,
        schema,
        renderTopToolbarCustomActions,
        initQuery,
        onQueryChange,
        renderRowActions,
        tableOptionsOverrides,
        permanentlySelectedRows,
        emptyChecker,
        runQuery
    }: BaseTableProps<T>) => {

    const [data, setData] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isReFetching, setIsReFetching] = useState(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(initQuery?.columnFilters || []);
    const [globalFilter, setGlobalFilter] = useState<string>(initQuery?.globalFilter || '');
    const [sorting, setSorting] = useState<MRT_SortingState>(initQuery?.sorting || []);
    const page_size_pref = localStorage.getItem(tableTitle + "_page_size")
    const [pagination, setPagination] = useState<MRT_PaginationState>(
        initQuery?.pagination || { pageIndex: 0, pageSize: DEFAULT_ITEMS_PER_PAGE }
    );

    const [rowCount, setRowCount] = useState(pagination.pageSize * (pagination.pageIndex + 1));

    /* Selection state */
    const initSelection: { [key: string]: boolean } = {};
    permanentlySelectedRows?.forEach((id: number) => {
        initSelection[PRIMARY_ID_PREFIX + id] = true;
    })
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(initSelection);

    /* Table Preferences */
    const tablePreferences = JSON.parse(
        localStorage.getItem(tableTitle + "_table_preferences") || "{}"
    );
    const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
        tablePreferences.columnOrder || ['mrt-row-select', ...schema.map((c) => c.key)]
    );
    const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
        {
            left: ["mrt-row-actions", "mrt-row-expand", "mrt-row-select", ...(tablePreferences?.columnPinning?.left || [])]
                .filter((value, index, array) => array.indexOf(value) === index),
            right: [...(tablePreferences?.columnPinning?.right || [])]
        }
    );
    const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
        tablePreferences.columnSizing || {}
    );
    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
        tablePreferences.columnVisibility || {}
    );

    useEffect(() => {
        const preferences = JSON.stringify({
            columnSizing,
            columnOrder,
            columnPinning,
            columnVisibility
        })
        localStorage.setItem(tableTitle + "_table_preferences", preferences)
    }, [
        columnSizing,
        columnOrder,
        columnPinning,
        columnVisibility,
    ])

    const defaultFilterFns: { [key: string]: LiteralUnion<string & MRT_FilterOption> } = useMemo(() => {
        const defaultFilterFns: { [key: string]: LiteralUnion<string & MRT_FilterOption> } = {};
        schema.forEach((c) => {
            const defaultFn = dataFieldFilters[c.field_type as DataFieldType].columnFilterModeOptions?.[0]
            if (defaultFn) {
                defaultFilterFns[c.key] = defaultFn;
            }
        })
        return {...defaultFilterFns, ...initQuery?.columnFilterFns} as {     [key: string]: LiteralUnion<string & MRT_FilterOption>; };
    }, [schema, initQuery, runQuery]);

    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
        {...defaultFilterFns, },
    );

    const columns: MRT_ColumnDef<T>[] = useMemo(() => {
        const columnDefs: MRT_ColumnDef<T>[] = [];
        columnDefs.push({
            id: "id",
            header: "ID",
            accessorKey: "id",
            size: 60,
            enableFilterMatchHighlighting: false,
            enableGlobalFilter: false,
            enableColumnFilter: false,
            enableColumnFilterModes: false,
            enableColumnActions: false,
            enableSorting: false,
            enableHiding: false,
            enablePinning: false,
            enableResizing: false,
            enableClickToCopy: true
        })
        schema.filter(c=>c.key!=="id").forEach((c) => {
            if (c.field_type === "json") {
                c = c as IDataFieldJSON<T>;
                if (c.schema.column_type === "OBJECT") {
                    const jsonSchema = c.schema as IColumnObject;
                    jsonSchema.properties.forEach((p) => {
                        const filterVariants = incidentColFilters[p.column_type as TColumnTypes]?.filterVariant;
                        const filterVariant = filterVariants ? filterVariants[columnFilterFns[c.key + "." + p.key]] : "text";
                        const colProps: MRT_ColumnDef<T> = {
                            id: c.key + "." + p.key,
                            header: p.title,
                            accessorKey: c.key + "." + p.key,
                            Cell: ({renderedCellValue}) => {
                                return <span style={{textAlign: "left"}}>{
                                    incidentColRenderers[p.column_type as TColumnTypes](renderedCellValue,  {emptyChecker})
                                }</span>
                            },
                            size: getColDefaultWidth(p),
                            enableResizing: true,
                            enableColumnFilter: true,
                            enableSorting: true,
                            filterSelectOptions: ((p as IColumnSelect).options || []).map((o) => {
                                return {
                                    label: o,
                                    value: o
                                }
                            }),
                            columnFilterModeOptions: incidentColFilters[p.column_type as TColumnTypes].columnFilterModeOptions,
                            filterVariant: typeof filterVariant === "string" ? filterVariant : undefined,
                            Filter: typeof filterVariant === "function" ? filterVariant : undefined,
                        }
                        columnDefs.push(colProps);
                    })
                }
                // TODO: handle non-object JSON values
            } else {
                const filterVariants = dataFieldFilters[c.field_type as DataFieldType]?.filterVariant;
                const filterVariant = filterVariants ? filterVariants[columnFilterFns[c.key]] : "text";
                const colProps: MRT_ColumnDef<T> = {
                    id: c.key,
                    header: c.title,
                    accessorKey: c.key,
                    Cell: ({renderedCellValue}) => {
                        return <span style={{textAlign: "left"}}>{
                            dataFieldRenderers[c.field_type as Exclude<DataFieldType, "json">](
                                renderedCellValue,
                                {emptyChecker}
                            )
                        }</span>
                    },
                    size: getDataFieldDefaultWidth(c),
                    enableResizing: true,
                    enableColumnFilter: true,
                    enableSorting: true,
                    filterSelectOptions: ((c as IDataFieldSelect<T>).options || []),
                    columnFilterModeOptions: dataFieldFilters[c.field_type as Exclude<DataFieldType, "json">].columnFilterModeOptions,
                    filterVariant: typeof filterVariant === "string" ? filterVariant : undefined,
                    Filter: typeof filterVariant === "function" ? filterVariant : undefined,
                }
                columnDefs.push(colProps);
            }
        })
        return columnDefs;
    }, [schema, runQuery])

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await runQuery({
                columnFilters,
                columnFilterFns,
                globalFilter,
                pagination,
                sorting,
            }) as IQueryResult<T>;
            if("error" in response){
                setIsError(true);
                return;
            }
            setRowCount(response.rowCount);
            setData(response.rows);
            onQueryChange?.({
                columnFilters,
                columnFilterFns,
                globalFilter,
                pagination,
                sorting,
            });
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
            setIsReFetching(false);
        }
    }, [columnFilterFns, columnFilters, globalFilter, pagination, sorting]);

    useEffect(() => {
        fetchData().then(()=>{});
    }, [fetchData]);

    const tableOptions: MRT_TableOptions<T> = {
        columns,
        data,
        enableExpandAll: false, //hide expand all double arrow in column header
        enableExpanding: false,
        filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableMultiSort: true,
        enableFullScreenToggle: false,
        paginateExpandedRows: true,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                enableResizing: false, //allow resizing
                size: 30, //make the expand column wide
            },
            'mrt-row-actions': {
                header: 'Actions', //change header text
                size: 90, //make actions column wider
                grow: false
            },
        },

        enableColumnDragging: true,
        enableColumnOrdering: true,
        muiColumnDragHandleProps: {
            title: "Move Column"
        },

        enableColumnResizing: true,
        enableColumnPinning: true,
        layoutMode:'grid-no-grow',
        columnResizeDirection: "ltr",


        enableRowActions: true,

        enableSubRowSelection: false,
        positionToolbarAlertBanner: "none",
        autoResetPageIndex: false,
        initialState: {
            density: "compact",
            showGlobalFilter: true,
        },
        state: {
            columnFilters,
            columnFilterFns,
            globalFilter,
            isLoading,
            pagination,
            sorting,
            rowSelection,
            showAlertBanner: isError,
            showProgressBars: isReFetching,
            columnOrder,
            columnPinning,
            columnVisibility,
            columnSizing
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,

        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        onColumnSizingChange: setColumnSizing,
        onColumnVisibilityChange: setColumnVisibility,


        getRowId: (row: T) => {return PRIMARY_ID_PREFIX + row.id},

        renderTopToolbarCustomActions,
        renderRowActions,

        rowCount: rowCount,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        muiPaginationProps:{
            rowsPerPageOptions: [10, 20, 50],
            showFirstButton: false,
            showLastButton: false,
        },
        muiTableContainerProps: {
            sx:{maxHeight: "65vh"}
        },
        enableStickyHeader: true,
        muiTableHeadCellProps: {
            sx: {
                '& .Mui-TableHeadCell-Content-Wrapper': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            },
        },
        ...tableOptionsOverrides,
    };

    const table = useMaterialReactTable(tableOptions);

    return <MaterialReactTable table={table}/>;
};

export default BaseTable;