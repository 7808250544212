import React from 'react'
import {CircularProgress, Stack} from "@mui/material";
import {ISource, ISourcesQuery} from "../../services/entities/sources";
import SourcesPicker from "./SourcesPicker";
import {ENTITIES} from "../../services/entities/entities";


interface IProps {
    multiple: boolean,
    selected: ISource[],
    onSelect: (selected: ISource[]) => void,
    query: ISourcesQuery
}

interface IState {
    loading: boolean,
    error?: string,
    sources: ISource[] | null,
}


class ExistingSourcesPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            sources: null,
        }
    }

    async componentDidMount() {
        await this.query();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query)) {
            await this.query()
        }
    }

    private async query() {
        if (this.state.loading) {
            return
        }
        this.setState((curr) => ({
            ...curr, loading: true, error: undefined
        }), async () => {
            const res = await ENTITIES["SOURCES"].query(this.props.query);
            if (res && !("error" in res)) {
                this.setState((curr) => ({
                    ...curr,
                    sources: res.rows,
                    loading: false,
                    error: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    sources: null,
                    loading: false,
                    error: res.error || "Unknown Error Occurred"
                }))
            }
        })
    }

    render() {
        return <Stack direction={"column"}>
            {this.state.loading ? <CircularProgress/> : null}
            {this.state.error ? <p>{this.state.error}</p> : null}
            {
                this.state.sources ?
                    <SourcesPicker
                        data={
                            this.state.sources
                        }
                        multiple={this.props.multiple}
                        selected={this.props.selected}
                        onSelect={this.props.onSelect}
                    /> :
                    null
            }
        </Stack>
    }
}

export default ExistingSourcesPicker;