import {
    DataFieldType,
    DataItem,
    DataQuery,
    IDataField, IDataFieldJSON,
    IDataFieldSelect,
    IDataFieldStr,
    IQueryResult
} from "./entityFields";
import server, {IErrorResponse} from "../server";
import React from "react";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import {E_ENTITIES} from "../types/entities";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";


export interface IEntity<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> {
    title: string;
    endpoint: string;
    slug: string;
    fields: IDataField<ItemType>[];
    defaultNewItem: ItemType;
    defaultQuery: DataQuery<ItemType>;
    accordionHeader: (r: ItemType) => React.ReactElement;
    additionalComponents?: (data: ItemType, setData: (data: ItemType) => any) => React.ReactElement;
    query: (query: QueryType) => Promise<IQueryResult<ItemType> | IErrorResponse>;
    get: (id: number) => Promise<ItemType | IErrorResponse>;
    save: (items: ItemType[], idsToDelete: number[]) => Promise<ISavedRows | IErrorResponse>;
    saveSingle: (item: ItemType) => Promise<{ savedId: number } | IErrorResponse>;
}

interface ISavedRows {
    errors: string[],
    deleted_ids: number[],
    inserted_ids: number[],
    updated_ids: number[],
}

export class Entity<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    implements IEntity<ItemType, QueryType> {
    title: string = "";
    endpoint: string = "";
    slug: string = "";
    fields: IDataField<ItemType>[] = [];
    defaultNewItem: ItemType = {} as ItemType;
    defaultQuery: DataQuery<ItemType> = {
        pagination: {pageIndex: 0, pageSize: 15},
        columnFilters: [],
        columnFilterFns: {},
        globalFilter: "",
        sorting: []
    }
    accordionHeader: (r: ItemType) => React.ReactElement = () => <></>;
    additionalComponents?: (data: ItemType, setData: (data: ItemType) => any) => React.ReactElement = () => <></>;
    relatedEntities?: (id: number) => ({
        entity: E_ENTITIES,
        query: DataQuery<any>
    }[])

    async query(query: QueryType): Promise<IQueryResult<ItemType> | IErrorResponse> {
        return await server.post(`${this.endpoint}/query/`, query);
    }

    async get(id: number): Promise<ItemType | IErrorResponse> {
        return await server.get(`${this.endpoint}/${id}`);
    }

    async save(items: ItemType[], idsToDelete: number[]): Promise<ISavedRows | IErrorResponse> {
        return await server.post(`${this.endpoint}/`, {data: items, deleted_rows: idsToDelete});
    }

    async saveSingle(item: ItemType): Promise<{ savedId: number } | IErrorResponse> {
        const saveResult: (ISavedRows | IErrorResponse) = await server.post(`${this.endpoint}/`, {
            data: [item],
            deleted_rows: []
        });
        if (saveResult && !("error" in saveResult)) {
            if ((saveResult as ISavedRows).updated_ids.length > 0) {
                return {savedId: (saveResult as ISavedRows).updated_ids[0]};
            }
            if ((saveResult as ISavedRows).inserted_ids.length > 0) {
                return {savedId: (saveResult as ISavedRows).inserted_ids[0]};
            }
            if ((saveResult as ISavedRows).deleted_ids.length > 0) {
                return {savedId: (saveResult as ISavedRows).deleted_ids[0]};
            }
            if ((saveResult as ISavedRows).errors.length > 0) {
                return {
                    error: (saveResult as ISavedRows).errors.join(", ")
                };
            }
        } else {
            return saveResult as IErrorResponse;
        }
        return {error: "error - failed to save data"};
    }


    DataFieldRenderer: {
        [key in DataFieldType]:
        (f: IDataField<ItemType>, value: any, onChange: (value: any) => any) => React.ReactElement
    } = {
        "str": (f: IDataField<ItemType>, value, onChange) => <TextField
            disabled={!!f.disabled}
            label={f.title}
            key={f.key}
            value={value || ""}
            variant="outlined"
            multiline={(f as IDataFieldStr<ItemType>).multi_line}
            placeholder={f.title}
            onChange={(e) => {
                onChange(e.target.value);
            }}
        />,
        "json": (f: IDataField<ItemType>, value, onChange) => <TextField
            disabled={!!f.disabled}
            label={f.title}
            key={f.key}
            value={JSON.stringify(value) || ""}
            variant="outlined"
            multiline={(f as IDataFieldStr<ItemType>).multi_line}
            placeholder={f.title}
            onChange={(e) => {
                onChange(JSON.parse(e.target.value));
            }}
        />,
        "datetime": (f: IDataField<ItemType>, value, onChange) => <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                disabled={!!f.disabled}
                label={f.title}
                value={value}
                views={['year', 'month', 'day']}
                format={"DD/MM/YYYY"}
                onChange={(v) => {
                    if (onChange) {
                        onChange(
                            v ? v.format("YYYY-MM-DD") : null
                        );
                    }
                }}
            />
        </LocalizationProvider>,
        "number": (f: IDataField<ItemType>, value, onChange) => <TextField
            disabled={!!f.disabled}
            label={f.title}
            key={f.key}
            value={value || ""}
            variant="outlined"
            placeholder={f.title}
            onChange={(e) => {
                onChange(e.target.value);
            }}
        />,
        "bool": (f: IDataField<ItemType>, value, onChange) =>
            <FormControlLabel key={f.key} control={
                <Switch
                    disabled={!!f.disabled}
                    checked={!!value}
                    onChange={(e) => {
                        onChange(e.target.checked);
                    }}
                />
            } label={f.title}/>,
        "select": (f: IDataField<ItemType>, value, onChange) => {
            return <FormControl
                key={f.key}
            >
                <InputLabel
                >
                    {f.title}
                </InputLabel>
                <Select
                    label={f.title}
                    variant="outlined"
                    value={value}
                    className={"fill-width-util"}
                    disabled={!!f.disabled}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                >
                    {
                        (f as IDataFieldSelect<ItemType>).options.map((o, i: number) => {
                            return <MenuItem value={o.value} key={"option_" + i}>{o.label}</MenuItem>
                        })}
                </Select>
            </FormControl>
        }
    }
}
