import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import React from "react";
import {Stack} from "@mui/material";
import ProportionsBar from "../../UIComponents/ProportionsBar/ProportionsBar";

export interface IInclusionKeyword extends DataItem {
    keyword: string;
    stemmed?: string;
    exact: boolean;
    regex: boolean;
    effect?: {
        approved: number,
        removed: number
    }
}

export interface IInclusionKeywordsQuery extends DataQuery<IInclusionKeyword> {}

export class InclusionKeyword extends Entity<IInclusionKeyword, IInclusionKeywordsQuery> {
    title = "Inclusion Keywords";
    endpoint = "keywords/inclusion"
    slug = "keywords";
    fields: IDataField<IInclusionKeyword>[] = [
        {title: "keyword", key: "keyword", field_type: "str"},
        {title: "stemmed", key: "stemmed", field_type: "str", disabled: true},
        {title: "exact", key: "exact", field_type: "bool"},
        {title: "regex", key: "regex", field_type: "bool"},
    ];
    defaultNewItem = {
        keyword: "",
        stemmed: "",
        exact: false,
        regex: false
    };
    accordionHeader = (r: IInclusionKeyword) => {
        return r.keyword ? <Stack sx={{width: "100%"}}>
            <span>{r.keyword}</span>
            <ProportionsBar sections={[
                {title: "Approved Incidents", color: "primary", amount: r.effect?.approved || 0},
                {title: "Dismissed Incidents", color: "error", amount: r.effect?.removed || 0},
            ]}/>
        </Stack> : <span>new keyword</span>
    }
}