import React from 'react';
import {
    Button,
    CircularProgress, Divider,
    Stack,
} from "@mui/material";
import {DataItem, DataQuery} from "../../services/entities/entityFields";
import EntityEditor from "./EntityEditor";
import withNotification, {WithNotificationProps} from "../../UIComponents/IncidentNotifications/withNotifications";
import {NOTIFICATION_TYPE} from "../../UIComponents/IncidentNotifications/NotificationsContext";
import {Entity} from "../../services/entities/entityBase";

interface IProps<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> extends WithNotificationProps {
    entity: Entity<ItemType, QueryType>;
    id: number | null;
}

interface IState<ItemType> {
    loadingData: boolean;
    loadingError: string | null;
    data: ItemType | null;
    awaitingSave: boolean;
}

class EntityManager<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps<ItemType, QueryType>, IState<ItemType>> {

    constructor(props: IProps<ItemType, QueryType>) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            data: null,
            awaitingSave: false,
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<IProps<ItemType, QueryType>>) {
        const prevId = prevProps.id;
        const currId = this.props.id;
        if (prevId !== currId) {
            await this.fetchData();
        }
    }

    private async fetchData() {
        if (this.state.loadingData) {
            return;
        }
        const currId = this.props.id;
        if(currId === null) {
            this.setState({
                data: this.props.entity.defaultNewItem
            });
            return;
        }
        this.setState({loadingData: true}, async () => {
            const res = await this.props.entity.get(currId);
            if (res && !("error" in res)) {
                this.setState({
                    data: res,
                    loadingData: false,
                    loadingError: null
                });
            } else {
                this.setState({
                    loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                });
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.ERROR,
                    actionType: null,
                    title: res?.error || "error - failed to load data"
                });
            }
        });
    }

    private async saveData() {
        if (this.state.awaitingSave || !this.state.data) {
            return;
        }
        this.setState({awaitingSave: true}, async () => {
            const data = this.state.data;
            if (data === null) {
                this.setState({
                    awaitingSave: false,
                });
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.ERROR,
                    actionType: null,
                    title: "error - couldn't save data"
                });
                return;
            }
            const res = await this.props.entity.saveSingle(data);
            if (res && !("error" in res)) {
                this.setState({
                    awaitingSave: false,
                }, () => {
                    this.props.notificationContext.addNotification({
                        notificationType: NOTIFICATION_TYPE.SAVE,
                        actionType: null,
                        title: "data saved"
                    });
                    this.fetchData();
                });
            } else {
                this.setState({
                    awaitingSave: false,
                });
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.ERROR,
                    actionType: null,
                    title: res?.error || "error - couldn't save data"
                });
            }
        });
    }

    render() {
        const data = this.state.data;
        return <div>{
            this.state.loadingData ? <CircularProgress/> : (
                this.state.loadingError ? <span className={"data-load-error"}>
                    {this.state.loadingError}
                </span> : <Stack
                    direction={"column"}
                    gap={1} divider={<Divider orientation="horizontal" flexItem/>}
                >
                    {
                        data ? <EntityEditor
                            entityId={this.props.id || null}
                            entity={this.props.entity}
                            data={data}
                            setData={newData => this.setState({data: newData})}
                        /> : <CircularProgress/>
                    }
                    <div className={"save-section-wrap"}>
                        {
                            this.state.awaitingSave ?
                                <CircularProgress/> :
                                <Button
                                    onClick={async () => {
                                        await this.saveData();
                                    }}
                                    variant={"outlined"}
                                >
                                    Save
                                </Button>
                        }
                    </div>
                </Stack>
            )
        }
        </div>;
    }
}

export default withNotification(EntityManager);