import React from 'react';
import {DataItem, DataQuery, IQueryResult} from '../../services/entities/entityFields';
import {Entity} from "../../services/entities/entityBase";
import EntitiesTable from "./EntitiesTable";
import {IErrorResponse} from "../../services/server";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import AlignDir from "../../services/languages/AlignDir";
import {Button, Chip, IconButton, Stack, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import withRouter, {IRouterProps} from "../../services/withRouter";
import {AddCircle} from "@mui/icons-material";

interface IProps<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> extends IRouterProps {
    entity: Entity<ItemType, QueryType>,
    tableTitle?: string,
    initQuery?: DataQuery<ItemType>,
    onQueryChange?: (query: DataQuery<ItemType>) => any,
}

interface IState {}

class BasicEntitiesTable<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps<ItemType, QueryType>, IState> {
    constructor(props: IProps<ItemType, QueryType>) {
        super(props);
        this.state = {};
    }

    render() {
        const {entity} = this.props;
        return <AlignDir direction={"ltr"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <EntitiesTable
                    runQuery={
                        async (query) => {
                            return await (
                                entity.query as (query: DataQuery<ItemType>) => Promise<IQueryResult<ItemType> | IErrorResponse>
                            )(query)
                        }
                    }
                    onQueryChange={this.props.onQueryChange}
                    schema={entity.fields}
                    tableTitle={this.props.tableTitle || entity.title}
                    initQuery={this.props.initQuery}
                    renderRowActions={(rowProps) => {
                        const row = rowProps.row;
                        const rowId = row.original.id;
                        return <Stack direction={"row"} alignItems={"center"} gap={1}>
                            {
                                <Tooltip
                                    title={"לעיון ועריכה"}
                                    arrow
                                    disableInteractive
                                >
                                    <a
                                        href={"/" + entity.slug + "/" + rowId}
                                        onClick={(e) => {
                                            this.props.navigate({
                                                pathname: "/" + entity.slug + "/" + rowId,
                                            });
                                        }}
                                    >
                                        <IconButton color={"primary"}>
                                            <EditIcon/>
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            }
                        </Stack>
                    }}
                    renderTopToolbarCustomActions={() => {
                        return <Stack direction={"row"} gap={2}>
                            <a
                                href={"/" + entity.slug + "/new"}
                                onClick={async () => {
                                    this.props.navigate({
                                        pathname: "/" + entity.slug + "/new",
                                    });
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<AddCircle/>}
                                >
                                    New {entity.title}
                                </Button>
                            </a>
                        </Stack>
                    }}
                />
            </LocalizationProvider>
        </AlignDir>;
    }
}

export default withRouter(BasicEntitiesTable);