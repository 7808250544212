import React from 'react'
import "./WebsitePreviewer.scss"
import server from "../../services/server";
import {
    Button,
    CircularProgress,
    Stack, Step, StepButton, Stepper,
    TextField
} from "@mui/material";
import {HighlightAlt, Preview} from "@mui/icons-material";
import HTMLSelectorGenerator from "./HTMLSelectorGenerator";
import {getTextDirection} from "../../services/multiLangUI";
import dayjs from "dayjs";
import {ISourceSelectors} from "../../services/entities/sources";
import SourcesPreviewPostsPicker from "../../UIComponents/ListPicker/SourcesPreviewPostsPicker";


interface IItemDetails {
    title?: string,
    publication_date?: string,
    body?: string
}

interface IProps {
    url: string | null,
    load_in_browser: boolean,
    selectors: ISourceSelectors,
    setSelectors: (newSelectors: ISourceSelectors) => any,
}

interface IState {
    stage: "INIT" | "FRONTPAGE" | "SINGLE_ITEM",
    selectors: ISourceSelectors,
    loadingFrontPagePreview: boolean,
    frontPagePreviewError?: string,
    frontPageHTML: string | null,
    itemUrl: string | null,
    loadingItemPreview: boolean,
    itemPreviewError?: string,
    itemHTML: string | null,
    loadingItemDetailsPreview: boolean,
    itemDetailsPreviewError?: string,
    itemDetails: IItemDetails | null,
    focusedDetailsField: "title_selector" | "date_selector" | "body_selector" | null,
    samplingArticleSelectors: boolean,
}


class WebsitePreviewer extends React.Component<IProps, IState> {
    private endPoint = "sources";

    constructor(props: IProps) {
        super(props);
        this.state = {
            stage: "FRONTPAGE",
            selectors: JSON.parse(JSON.stringify(props.selectors)),
            loadingFrontPagePreview: false,
            frontPageHTML: null,
            itemUrl: null,
            loadingItemPreview: false,
            itemHTML: null,
            loadingItemDetailsPreview: false,
            itemDetails: null,
            focusedDetailsField: null,
            samplingArticleSelectors: false
        }
    }

    async componentDidMount(){
        await this.previewSourceFrontPage();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const prevUrl = prevProps.url;
        const currUrl = this.props.url;
        if (prevUrl !== currUrl) {
            this.setState((curr) => ({
                ...curr,
                loadingFrontPagePreview: false, frontPageHTML: null, stage: "INIT"
            }), () => {
                this.previewSourceFrontPage();
            })
        }
        if (this.state.selectors.article_entry_selector !== this.props.selectors.article_entry_selector) {
            const stage = this.state.stage === "SINGLE_ITEM" ? "FRONTPAGE" : this.state.stage;
            this.setState((curr) => ({
                ...curr,
                stage: stage,
                selectors: JSON.parse(JSON.stringify(this.props.selectors))
            }))
        }
        if (
            this.state.selectors.title_selector !== this.props.selectors.title_selector ||
            this.state.selectors.date_selector !== this.props.selectors.date_selector ||
            this.state.selectors.body_selector !== this.props.selectors.body_selector
        ) {
            this.setState((curr) => ({
                ...curr,
                selectors: JSON.parse(JSON.stringify(this.props.selectors))
            }), () => {
                if (this.state.stage === "SINGLE_ITEM") {
                    this.previewArticleDetails();
                }
            })
        }
    }

    private async previewSourceFrontPage() {
        if (this.state.loadingFrontPagePreview || !this.props.url) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingFrontPagePreview: true, frontPagePreviewError: undefined, stage: "FRONTPAGE"
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/frontpage/", {
                url: this.props.url,
                load_in_browser: this.props.load_in_browser,
                source_type: "news_site"
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, frontPageHTML: res,
                    loadingFrontPagePreview: false,
                    frontPagePreviewError: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    loadingFrontPagePreview: false,
                    frontPagePreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private previewSingleArticle() {
        if (this.state.loadingItemPreview || !this.state.itemUrl) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingItemPreview: true, itemPreviewError: undefined, stage: "SINGLE_ITEM"
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/frontpage/", {
                url: this.state.itemUrl,
                load_in_browser: this.props.load_in_browser,
                source_type: "news_site"
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, itemHTML: res,
                    loadingItemPreview: false,
                    itemPreviewError: undefined
                }), async () => {
                    await this.previewArticleDetails();
                })
            } else {
                this.setState((curr) => ({
                    ...curr,
                    itemHTML: null,
                    loadingItemPreview: false,
                    itemPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private async previewArticleDetails() {
        if (this.state.loadingItemDetailsPreview || !this.state.itemUrl) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingItemDetailsPreview: true, itemDetailsPreviewError: undefined
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/article_details/", {
                url: this.state.itemUrl,
                load_in_browser: this.props.load_in_browser,
                article_entry_selector: this.props.selectors.title_selector,
                date_selector: this.props.selectors.date_selector,
                body_selector: this.props.selectors.body_selector,
                title_selector: this.props.selectors.title_selector,
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, itemDetails: res,
                    loadingItemDetailsPreview: false,
                    itemDetailsPreviewError: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    itemDetails: null,
                    loadingItemDetailsPreview: false,
                    itemDetailsPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private isValidHttpUrl = (str: string) => {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    private getFieldTitleWithSamplingToggle = (selector: "title_selector" | "date_selector" | "body_selector" | null, label: string) => {
        return <Stack direction={"row"} alignItems={"center"} gap={2} dir={"ltr"}>
            <Button
                sx={{
                    "& .MuiButton-startIcon": {
                        marginRight: 0,
                        marginLeft: 0
                    },
                    minWidth: 0,
                    padding: "5px 5px"
                }}
                variant={
                    this.state.samplingArticleSelectors && this.state.focusedDetailsField === selector ? "contained" : "outlined"
                }
                onClick={() => {
                    if (this.state.focusedDetailsField === selector) {
                        this.setState((curr) => ({
                            ...curr,
                            focusedDetailsField: null,
                        }))
                    } else {
                        this.setState((curr) => ({
                            ...curr,
                            focusedDetailsField: selector,
                            samplingArticleSelectors: true
                        }))
                    }
                }}
                color="primary"
                startIcon={<HighlightAlt/>}
            />
            <span>{label}</span>
        </Stack>
    }

    render() {
        const url = this.props.url;
        return <Stack direction={"column"} gap={1}>
            <Stepper activeStep={this.state.stage === "FRONTPAGE" ? 0 : 1}>
                <Step completed={!!this.state.itemUrl}>
                    <StepButton
                        onClick={() => this.setState({stage: "FRONTPAGE"})}
                    >
                        Front Page Parsing
                    </StepButton>
                </Step>
                <Step>
                    <StepButton
                        onClick={() => this.state.itemUrl && this.setState({stage: "SINGLE_ITEM"})}
                        disabled={!this.state.itemUrl}
                    >
                        Article Parsing
                    </StepButton>
                </Step>
            </Stepper>
            {
                this.state.stage === "FRONTPAGE" ? <Stack direction={"column"}>
                        <Stack direction={"row"} className={"entries-selection-wrap"}>
                            <Stack direction={"column"} className={"frontpage-preview-wrap"}>
                                {this.state.loadingFrontPagePreview ? <CircularProgress/> : null}
                                {this.state.frontPagePreviewError ? <p>{this.state.frontPagePreviewError}</p> : null}
                                {
                                    this.state.frontPageHTML ?
                                        <HTMLSelectorGenerator
                                            key={this.props.url || ""}
                                            src_html={this.state.frontPageHTML}
                                            selector={this.props.selectors.article_entry_selector || undefined}
                                            setSelector={(s) => {
                                                const newSelectors = this.props.selectors;
                                                newSelectors.article_entry_selector = s;
                                                this.props.setSelectors(newSelectors)

                                            }}
                                            singleSelect={false}
                                        /> :
                                        null
                                }
                            </Stack>
                            <Stack direction={"column"} className={"entries-preview-wrap"}>
                                <SourcesPreviewPostsPicker
                                    multiple={false}
                                    selected={[]}
                                    onSelect={(selectedUrls) => {
                                        if (selectedUrls.length) {
                                            this.setState((curr) => ({
                                                ...curr,
                                                stage: "SINGLE_ITEM",
                                                itemUrl: selectedUrls[0].url || null,
                                                itemHTML: null,
                                            }), () => {
                                                this.previewSingleArticle()
                                            })
                                        }
                                    }}
                                    source_type={"news_site"}
                                    url={this.props.url}
                                    load_in_browser={this.props.load_in_browser}
                                    selectors={JSON.parse(JSON.stringify(this.props.selectors))}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    : null
            }
            {
                this.state.stage === "SINGLE_ITEM" ? <Stack direction={"column"}>
                        <Stack direction={"row"} className={"article-parsing-wrap"}>
                            <Stack direction={"column"} className={"article-preview-wrap"}>
                                {this.state.loadingItemPreview ? <CircularProgress/> : null}
                                {this.state.itemPreviewError ? <p>{this.state.itemPreviewError}</p> : null}
                                {
                                    this.state.itemHTML ?
                                        <HTMLSelectorGenerator
                                            key={this.state.itemUrl || ""}
                                            src_html={this.state.itemHTML}
                                            selector={this.state.focusedDetailsField ? this.state.selectors[this.state.focusedDetailsField] || undefined : undefined}
                                            setSelector={(s) => {
                                                if (this.state.focusedDetailsField && this.state.samplingArticleSelectors) {
                                                    const newSelectors = this.props.selectors;
                                                    newSelectors[this.state.focusedDetailsField] = s;
                                                    this.props.setSelectors(newSelectors)
                                                    this.setState((curr) => ({...curr, samplingArticleSelectors: false}))
                                                }
                                            }}
                                            singleSelect={true}
                                        /> :
                                        null
                                }
                            </Stack>
                            <Stack direction={"column"} className={"article-details-wrap"}>
                                <p>Article Details</p>
                                {this.state.loadingItemDetailsPreview ? <CircularProgress/> : null}
                                {this.state.itemDetailsPreviewError ? <p>{this.state.itemDetailsPreviewError}</p> : null}
                                {
                                    this.state.itemDetails ?
                                        <Stack direction={"column"} gap={3}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                label={this.getFieldTitleWithSamplingToggle("title_selector", "Title")}
                                                placeholder={"Title"}
                                                value={this.state.itemDetails?.title || ""}
                                                variant="outlined"
                                                multiline={true}
                                                dir={getTextDirection(this.state.itemDetails?.title || "")}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "title_selector"
                                                    }))
                                                }}
                                            />
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                className={"fill-width-util"}
                                                label={this.getFieldTitleWithSamplingToggle("date_selector", "Date")}
                                                placeholder={"Publication Date"}
                                                value={dayjs(this.state.itemDetails?.publication_date).format("YYYY-MM-DD") || null}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "date_selector"
                                                    }))
                                                }}
                                            />
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                label={this.getFieldTitleWithSamplingToggle("body_selector", "Content")}
                                                placeholder={"Content"}
                                                value={this.state.itemDetails?.body || ""}
                                                variant="outlined"
                                                multiline={true}
                                                dir={getTextDirection(this.state.itemDetails?.body || "")}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "body_selector"
                                                    }))
                                                }}
                                            />
                                        </Stack> : null
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    : null
            }
        </Stack>
    }
}

export default WebsitePreviewer;