import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import React from "react";

export interface INullDataFlag extends DataItem {
    flag: string;
}

export interface INullDataFlagsQuery extends DataQuery<INullDataFlag> {}

export class NullDataFlag extends Entity<INullDataFlag, INullDataFlagsQuery> {
    title = "Empty Data Indicators";
    endpoint = "null_data_flags";
    slug = "null-flags"
    fields: IDataField<INullDataFlag>[] = [
        {title: "flag", key: "flag", field_type: "str"}
    ]
    defaultNewItem: INullDataFlag = {
        flag: ""
    };
    accordionHeader = (r: INullDataFlag) => <span>{r.flag || "new flag"}</span>
}