import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import React from "react";

export const TransparentTooltip = styled(({className, children, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));