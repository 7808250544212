import {DataItem, DataQuery, IDataField} from "./entityFields";
import {Entity} from "./entityBase";
import {IconButton, Stack} from "@mui/material";
import WebsitePreviewer from "../../pages/sources/WebsitePreviewer";
import SourcesPreviewPostsPicker from "../../UIComponents/ListPicker/SourcesPreviewPostsPicker";
import React from "react";
import LinkIcon from "@mui/icons-material/Link";
import {E_ENTITIES} from "../types/entities";
import {IPost} from "./posts";
import SourcePreviewer from "../../pages/sources/SourcePreviewer";
import {set} from "js-cookie";

export type TSourceType = "telegram" | "news_site"

export interface ISourceSelectors {
    title_selector?: string | null,
    date_selector?: string | null,
    body_selector?: string | null,
    article_entry_selector?: string | null
}

export interface ISource extends ISourceSelectors, DataItem {
    source_type: TSourceType;
    title: string;
    url: string;
    is_active?: boolean;
    load_in_browser?: boolean;
    max_load_time?: number;
}

export interface ISourcesQuery extends DataQuery<ISource> {}

export class Source extends Entity<ISource, ISourcesQuery> {
    title = "Sources";
    endpoint = "sources";
    slug = "sources"
    fields: IDataField<ISource>[] = [
        {title: "Source Name", key: "title", field_type: "str"},
        {title: "URL", key: "url", field_type: "str"},
        {
            title: "Source Type", key: "source_type", field_type: "select",
            options: [{value: "news_site", label: "חדשות"}, {value: "telegram", label: "טלגרם"}]
        },
        {title: "Enabled", key: "is_active", field_type: "bool"},
        {
            title: "Requires JS",
            key: "load_in_browser",
            field_type: "bool",
            hideWhen: (i) => (i.source_type !== "news_site")
        },
        {
            title: "Article Entry Selector (optional)",
            key: "article_entry_selector",
            field_type: "str",
            hideWhen: (i) => (i.source_type !== "news_site")
        },
        {
            title: "Title Selector (optional)",
            key: "title_selector",
            field_type: "str",
            hideWhen: (i) => (i.source_type !== "news_site")
        },
        {
            title: "Date Selector (optional)",
            key: "date_selector",
            field_type: "str",
            hideWhen: (i) => (i.source_type !== "news_site")
        },
        {
            title: "Body Selector (optional)",
            key: "body_selector",
            field_type: "str",
            hideWhen: (i) => (i.source_type !== "news_site")
        },
        {title: "Date Added", key: "date_added", field_type: "str", disabled: true},
    ];
    defaultNewItem: ISource = {
        title: "",
        url: "",
        source_type: "news_site",
        is_active: true,
        load_in_browser: false,
        article_entry_selector: null,
        title_selector: null,
        date_selector: null,
        body_selector: null,
    };
    accordionHeader = (r: ISource) => {
        return <Stack direction={"row"} alignItems={"center"}>
            <a href={r.id ? "/sources/" + r.id : "#"}
               onClick={(e) => {
                   e.stopPropagation()
               }}
            >
                {
                    r.source_type !== "news_site" ?
                        <span> {r.title ? `Telegram Channel (${r.url}) - ${r.title}` : "new telegram"}</span> :
                        <span>{r.title || "new source"}</span>
                }
            </a>
                {
                    r.url && r.source_type === "news_site" ?
                        <a href={r.url}>
                            <IconButton color={"primary"}>
                                <LinkIcon/>
                            </IconButton>
                        </a> : null
                }
        </Stack>
    };
    additionalComponents = (data: ISource, setData:(data: ISource)=>any) => (
        <SourcePreviewer data={data} setData={setData}/>
    );
    relatedEntities = (id: number)=>([
        {
            entity: "POSTS" as E_ENTITIES,
            query: {
                pagination: {pageIndex: 0, pageSize: 50},
                columnFilters: [{id: "source_id", value: id}],
                columnFilterFns: {"source_id": "equals"},
                sorting: [],
                globalFilter: ""
            } as DataQuery<IPost>
        }
    ])
}