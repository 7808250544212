import React from 'react'
import {FormControlLabel, IconButton, Stack, Switch, TextField} from "@mui/material";
import {IPost, IPostsQuery} from "../../services/entities/posts";
import ExistingPostsPicker from "./ExistingPostsPicker";
import SelfContainedPopover from "../SelfContainedComponents/selfContainedPopover";
import {FilterList} from "@mui/icons-material";
import {ENTITIES} from "../../services/entities/entities";


interface IProps {
    initQuery?: IPostsQuery
    multiple: boolean,
    selected: IPost[],
    onSelect: (selected: IPost[]) => void,
}

interface IState {
    query: IPostsQuery
}


class QueryableExistingPostsPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            query: this.props.initQuery || ENTITIES["POSTS"].defaultQuery,
        }
    }

    render() {
        return <Stack
            direction={"column"} gap={1}
            sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100%",
                width: "100%"
            }}
        >
            <SelfContainedPopover
                trigger={(popupVisibilitySetter) => {
                    return <Stack direction={"row"} alignItems={"start"}>
                        <IconButton
                            color={"primary"}
                            onClick={(e) => popupVisibilitySetter(e, true)}
                        >
                            <FilterList/>
                        </IconButton>
                    </Stack>
                }}
                content={() => {
                    return <Stack direction={"column"} gap={1}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            onChange={(e) => {
                                const searchQuery = e.target.value;
                                this.setState((curr) => ({
                                    query: {
                                        ...curr.query,
                                        search: searchQuery,
                                    },
                                }));
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={(e) => {
                                        const onlyRelevant = e.target.checked;
                                        this.setState((curr) => ({
                                            query: {
                                                ...curr.query,
                                                relevancy: onlyRelevant ? "relevant" : undefined,
                                            },
                                        }));
                                    }}
                                />
                            }
                            label="Only include relevant posts"
                        />
                    </Stack>
                }}
            />
            <ExistingPostsPicker
                multiple={this.props.multiple}
                selected={this.props.selected}
                onSelect={this.props.onSelect}
                query={this.state.query}
            />
        </Stack>
    }
}

export default QueryableExistingPostsPicker;