import React from 'react'
import { FormControl, TextField } from "@mui/material";
import {IColumnCity} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import {cn} from "../../../services/utils";
import {getTextDirection} from "../../../services/multiLangUI";

export default class IncidentFieldCity extends BaseIncidentField<IColumnCity> {
    render() {
        const column = this.props.column;
        const data = this.props.data && typeof this.props.data === "string" ? this.props.data : null;
        const emptyChecker = this.props.emptyChecker;
        const sources = this.props.sources;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;
        const onClick = this.props.onClick;
        const labelOverride = this.props.labelOverride

        return <div className={cn({
            "empty-data": emptyChecker(data),
            "card-leaf-data-wrap": true
        })}>
            <FormControl className={"fill-width-util"}>
                <div className={"sources-adornment"}>
                    {this.getLabelSourcesAdornment(column, sources, onSourcesClick)}
                </div>
                <TextField
                    label={labelOverride !== undefined ? labelOverride : column.title}
                    className={"fill-width-util"}
                    variant="outlined"
                    value={data||""}
                    dir={getTextDirection(data||"")}
                    InputProps={{
                        readOnly: !onEdit,
                    }}
                    onClick={()=>{
                        if(onClick){
                            onClick(data);
                        }
                    }}
                    onChange={(e)=>{
                        if(onEdit){
                            onEdit(e.target.value, null);
                        }
                    }}
                />
            </FormControl>
        </div>
    }
}