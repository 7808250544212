import React, {Component} from 'react';
import server from '../services/server';
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid, Stack,
} from '@mui/material';
import IncidentCountCard from '../UIComponents/IncidentCountCard/IncidentCountCard';
import AlignDir from "../services/languages/AlignDir";
import {CHARTS, IChartDataRow} from "./charts/chartComponents";
import withRouter from "../services/withRouter";
import SystemDigest from "./systemStatus/systemDigest";


interface ISystemSnapshot {
    approved: number,
    not_approved: number,
    removed: number,
    total: number
}

interface IState {
    current_stats: ISystemSnapshot | null,
    trendline: IChartDataRow[] | null
}

interface IProps {
}

class IncidentsStatus extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            current_stats: null,
            trendline: null,
        };
    }

    async componentDidMount() {
        await this.fetchTrendlineData();
    }

    fetchTrendlineData = async () => {
        const response = await server.get('insights/snapshots/');
        if (response) {
            const trendline: IChartDataRow[] = response.snapshots.map((
                s: {
                    id: number, taken_at: string,
                    not_approved: number, approved: number, removed: number, total: number
                }
            ) => {
                const date_taken_at = new Date(s.taken_at).toISOString().split('T')[0]
                return ["not_approved", "approved", "removed", "total"].map((key: string) => {
                    return {
                        primary_variable: date_taken_at,
                        secondary_variable: key,
                        count: s[key as "not_approved" | "approved" | "removed" | "total"]
                    }
                })
            }).flat()
            this.setState({current_stats: response.current_stats, trendline})
        }
    };

    render() {
        const {
            current_stats
        } = this.state;

        return (
            <div className={"page-wrap"}>
                <TopNavBar>
                    <div className="title-wrap">
                        Incidents Status
                    </div>
                </TopNavBar>
                <div className={"page-content content-wrap"}>
                    <AlignDir direction={"ltr"}>
                        <Stack direction={"column"} gap={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <IncidentCountCard
                                        incidentType="Total Incidents"
                                        count={current_stats?.total || 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <IncidentCountCard
                                        incidentType="Approved Incidents"
                                        count={current_stats?.approved || 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <IncidentCountCard
                                        incidentType="Not Approved Incidents"
                                        count={current_stats?.not_approved || 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <IncidentCountCard
                                        incidentType="Removed Incidents"
                                        count={current_stats?.removed || 0}
                                    />
                                </Grid>
                            </Grid>
                            <Card sx={{width: '100%'}}>
                                <CardContent>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="h5" component="h2">
                                            Incident Trendline
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                            Click on the color tabs to choose the status of your chart
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        {
                                            this.state.trendline ? CHARTS['LINE'].renderer({
                                                data: this.state.trendline,
                                                hideEmptyData: "HIDE"
                                            }) : null
                                        }
                                    </Box>
                                </CardContent>
                            </Card>
                            <SystemDigest/>
                        </Stack>
                    </AlignDir>
                </div>
            </div>
        );
    }
}

export default withRouter(IncidentsStatus)
