import React from 'react'
import {IColumn, IColumnArray, IColumnObject} from "../../services/types/columns";
import {CircularProgress, Divider, Stack} from "@mui/material";
import server from "../../services/server";
import {isEmpty} from "./dataTreatment";

export interface IIncidentInfoProps {
    incidentId?: number,
    data?: any,
    column?: IColumn,
    emptyChecker?: (v:any) => boolean,
    nested?: boolean
}
interface IState {
    column: IColumn | null,
    data: any | null,
    emptyChecker: ((v:any) => boolean) | null,
}


export default class IncidentInfoCard extends React.Component<IIncidentInfoProps, IState> {
    constructor(props: IIncidentInfoProps) {
        super(props);
        this.state = {
            column: props.column || null,
            data: props.data || null,
            emptyChecker: props.emptyChecker || null
        }
    }

    async componentDidMount() {
        const columnPromise = this.state.column || this.props.nested ?
            await Promise.resolve(null) :
            await server.get("columns/");
        const dataPromise = this.state.data || this.props.incidentId === undefined || this.props.nested ?
            await Promise.resolve(null) :
            await server.get("incident/" + this.props.incidentId);
        const emptyCheckerPromise = this.state.emptyChecker || this.props.nested ?
            await Promise.resolve(null) :
            await server.get("null_data_flags/");

        const [columnRes, dataRes, emptyCheckerRes] = await Promise.all([
            columnPromise,
            dataPromise,
            emptyCheckerPromise
        ]);

if (columnRes) {
    const column = (((columnRes as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
    this.setState({ column });
}

if (dataRes) {
    const data = dataRes.data || {};
    this.setState({ data });
}

if (emptyCheckerRes) {
    const emptyDataIndicators = emptyCheckerRes.map((x: { flag: string }) => x.flag);
    const emptyChecker = (v: any) => isEmpty(v, emptyDataIndicators);
    this.setState({ emptyChecker });
}
    }

    render() {
        const data = this.state.data;
        const column = this.state.column;
        const emptyChecker = this.state.emptyChecker;

        if((!data && !this.props.nested) || !column || !emptyChecker){
            return <CircularProgress/>
        }

        if(column.column_type === "OBJECT"){
            const columnAsObject = column as IColumnObject;
            return <Stack
                gap={1}
                direction={"column"}
                divider={<Divider orientation={"horizontal"}/>}
                sx={{width: "100%"}}
            >
                {columnAsObject.properties.map((p, i)=>{
                    return <Stack gap={2} direction={"row"} key={i}>
                        <b>{p.title}:</b>
                        <span style={{flexGrow: "1"}}>
                            <IncidentInfoCard
                                column={p}
                                data={data[p.key]}
                                emptyChecker={emptyChecker}
                                nested={true}
                            />
                        </span>
                    </Stack>
                })}
            </Stack>
        }
        if(column.column_type === "ARRAY"){
            const dataAsArr = Array.isArray(data) ? data : []
            return <Stack gap={1} direction={"column"} divider={<Divider orientation={"horizontal"}/>}>
                {dataAsArr.map((v, i)=>{
                    return <IncidentInfoCard
                        key={i}
                        column={(column as IColumnArray).entries}
                        data={v}
                        emptyChecker={this.props.emptyChecker}
                    />
                })}
            </Stack>
        }
        else{
            return <span>{data || ""}</span>
        }
    }
}