import React from 'react'
import { FormControl, TextField } from "@mui/material";
import { IColumnText } from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import {cn} from "../../../services/utils";
import {getTextDirection} from "../../../services/multiLangUI";

export default class IncidentFieldText extends BaseIncidentField<IColumnText> {
    render() {
        const column = this.props.column;
        const data = (this.props.data && typeof this.props.data === "string") ? this.props.data : "";
        const emptyChecker = this.props.emptyChecker;
        const sources = this.props.sources;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;
        const onClick = this.props.onClick;
        const labelOverride = this.props.labelOverride

        return <div className={cn({
            "empty-data": emptyChecker(data),
            "card-leaf-data-wrap": true,
            "wide-field": column.multi_line
        })}>
            <FormControl className={"fill-width-util"}>
                <div className={"sources-adornment"}>
                    {this.getLabelSourcesAdornment(column, sources, onSourcesClick)}
                </div>
                {/* TODO: removes rows attribute and fix error caused by multiline
                    https://github.com/mui/base-ui/issues/167 */}
                <TextField
                    className={"fill-width-util"}
                    label={labelOverride !== undefined ? labelOverride : column.title}
                    dir={getTextDirection(data||"")}
                    variant="outlined"
                    value={data||""}
                    multiline={column.multi_line}
                    rows={3}
                    InputProps={{
                        readOnly: !onEdit,
                    }}
                    onClick={()=>{
                        if(onClick){
                            onClick(data);
                        }
                    }}
                    onChange={(e)=>{
                        if(onEdit){
                            onEdit(e.target.value, null);
                        }
                    }}
                />
            </FormControl>
        </div>
    }
}