import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";

import {IconButton, Stack} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AlignDir from "../services/languages/AlignDir";
import EntityManager from "./entityCard/EntityManager";
import {DataItem, DataQuery} from "../services/entities/entityFields";
import {WithNotificationProps} from "../UIComponents/IncidentNotifications/withNotifications";
import {Entity} from "../services/entities/entityBase";

interface IProps
    extends WithNotificationProps, IRouterProps {
    entity: Entity<DataItem, DataQuery<DataItem>>;
}

interface IState {
    id: number | null
}

class EntityCard<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const idArg = this.props.params.id;
        let id;
        try {
            id = idArg === null || idArg === "new" ? null : parseInt(idArg);
        } catch (e) {
            id = null;
        }
        this.state = {
            id: id
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const idArg = this.props.searchParams.get("id");
        const id = idArg === null ? null : parseInt(idArg);
        if (this.state.id !== id) {
            this.setState((curr) => ({...curr, id: id}))
        }
    }

    render() {
        const id = this.state.id;
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} gap={2} alignItems={"center"}>

                    <div className="title-wrap">
                        {this.props.entity.title} ({this.state.id || "new"})
                    </div>
                </Stack>
            </TopNavBar>
            <AlignDir direction={"ltr"}>
                <div className={"page-content content-wrap"}>
                    <EntityManager
                        id={id}
                        entity={this.props.entity}
                    />
                </div>
            </AlignDir>
        </div>
    }
}

export default (withRouter(EntityCard));