import React from 'react'
import ListPicker from "./ListPicker";
import {ListItemText} from "@mui/material";
import {getTextDirection} from "../../services/multiLangUI";
import {IPartialPost} from "../../services/entities/posts";


interface IProps {
    data: IPartialPost[],
    multiple: boolean,
    selected: IPartialPost[],
    onSelect: (selected: IPartialPost[]) => void,
}


class PostsPicker extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        return <ListPicker
            data={this.props.data}
            id={"url"}
            multiple={this.props.multiple}
            onSelect={this.props.onSelect}
            renderer={(item: IPartialPost) => {
                const titlePreview = (item.preview_title || item.content || "No Content");
                const urlPreview = (item.url || "No URL");
                return <>
                    <ListItemText
                        primary={titlePreview}
                        secondary={urlPreview}
                        sx={{
                            "& .MuiListItemText-primary": {
                                direction: getTextDirection(titlePreview),
                                textAlign: getTextDirection(titlePreview) === "rtl" ? "right" : "left",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                display: "-webkit-box",
                                lineClamp: "2",
                                boxOrient: "vertical",
                                overflow: "hidden",
                            },
                            "& .MuiListItemText-secondary": {
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                display: "-webkit-box",
                                lineClamp: "1",
                                boxOrient: "vertical",
                                overflow: "hidden",
                            }
                        }}
                    />
                </>
            }}
            selected={this.props.selected}
        />
    }
}

export default PostsPicker;