import React from 'react'
import "./IncidentViewer.scss"
import Paper from '@mui/material/Paper';
import {
    Badge, Box,
    CircularProgress, Divider,
    IconButton, Modal,
    Popover,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {substr} from "stylis";
import server from "../../services/server";
import {
    IColumn,
    IColumnArray,
    IColumnObject, IColumnText,
} from "../../services/types/columns";
import {IIncident, IIncidentReport} from "../../services/types/incident";
import {isEmpty} from "./dataTreatment";
import {expandSourcesHandler} from "./fields/BaseIncidentField";
import IncidentField from "./fields/IncidentField";
import {CallSplit, OpenInNew, WebAsset} from "@mui/icons-material";
import SourcePreview, {ISourcePreview} from "./SourcePreview";
import {
    dismissMerge,
    mergeIncidents,
    setIncidentApprovalStatus,
    splitIncidentReportWithIds
} from "../../services/entities/incidents/incidentManager";
import {incidentStatus} from "../../services/types/incidentStatus";
import IncidentTable from "../incident_browser/IncidentTable";
import AlignDir from "../../services/languages/AlignDir";
import MergeSuggestions from "./MergeSuggestions";
import withNotifications, {WithNotificationProps} from "../../UIComponents/IncidentNotifications/withNotifications";
import {
    INCIDENT_ACTIONS, IIncidentSaveNotification,
    NOTIFICATION_TYPE
} from "../../UIComponents/IncidentNotifications/NotificationsContext";

interface IViewerProps {
    id: number,
    goToIncidentPage: (id: number) => any
}

type IProps = IViewerProps & WithNotificationProps

interface IState {
    incident: IIncident | null,
    columns: IColumnObject | null,
    expandReportDetails: boolean,
    emptyDataIndicators: string[] | null,
    loadingIncident: boolean,
    awaitingStatusChange: boolean,
    fieldSourcesPopoverAnchor: HTMLElement | null,
    sourcesPopoverAnchor: HTMLElement | null,
    fieldSourcesPopoverData: ISourcesPopoverData | null,
    mergePopupOpen: boolean,
    incidentError: null | string,
    sourcePreviews: ISourcePreview[],
    editingWorkspace: boolean,
    saveTimeout: NodeJS.Timeout | null,
    awaitingSave: boolean,
    scheduleReSave: boolean,
}

export interface ISourcesPopoverData {
    column: IColumn,
    data: IIncidentReport[],
    onSelect: (value: IIncidentReport) => void
}

const AUTO_SAVE_DELAY = 1000;

class IncidentViewer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            editingWorkspace: false,
            incident: null,
            columns: null,
            expandReportDetails: false,
            emptyDataIndicators: null,
            loadingIncident: false,
            awaitingStatusChange: false,
            sourcesPopoverAnchor: null,
            fieldSourcesPopoverAnchor: null,
            fieldSourcesPopoverData: null,
            mergePopupOpen: false,
            incidentError: null,
            sourcePreviews: [],
            saveTimeout: null,
            awaitingSave: false,
            scheduleReSave: false,
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchIncident(),
            this.fetchEmptyDataIndicators(),
            this.fetchColumns()
        ]);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        if (prevProps.id !== this.props.id) {
            await this.fetchIncident()
        }
    }

    private async fetchColumns() {
        const res = await server.get("columns/");
        if (res && !res.error) {
            const columns = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
            return await new Promise<void>((resolve) => {
                this.setState((current) => ({...current, columns}), () => {
                    resolve()
                })
            })
        }
    }

    private async fetchIncident() {
        if (this.state.loadingIncident) {
            return
        }

        return await new Promise<void>((resolve) => {
            this.setState((current) => ({...current, loadingIncident: true}), async () => {
                const res = await server.get("incident/" + this.props.id);
                if (res && !res.error) {
                    const incident = res;
                    try {
                        incident.title = incident.title || "incident #" + incident.id;

                    } catch (e) {
                        return null;
                    }
                    return resolve(await new Promise<void>((resolve) => {
                        this.setState((current) => ({
                            ...current,
                            incident,
                            incidentError: null,
                            loadingIncident: false,
                        }), () => {
                            resolve();
                        })
                    }))
                } else {
                    const incidentError = res && res.error ? res.error : "unknown error"
                    this.setState((current) => ({
                        ...current,
                        incidentError,
                        loadingIncident: false
                    }))
                }
            })
        })
    }

    async fetchEmptyDataIndicators() {
        const res = await server.get("null_data_flags/");
        const emptyDataIndicators = res.map((x: { flag: string }) => x.flag)
        return await new Promise<void>((resolve) => {
            this.setState((current) => ({
                ...current,
                emptyDataIndicators
            }), () => {
                resolve()
            })
        })
    }

    private async setIncidentStatus(status: incidentStatus) {
        const incident = this.state.incident;
        if (incident === null || this.state.awaitingStatusChange || this.state.awaitingSave) {
            return;
        }
        this.setState({awaitingStatusChange: true}, async () => {
            const setTo: { [key: number]: incidentStatus } = {};
            const prevStatus: { [key: number]: incidentStatus } = {};
            setTo[incident.id] = status;
            prevStatus[incident.id] = incident.approved;
            await setIncidentApprovalStatus(
                setTo,
                this.props.notificationContext,
                async () => {
                    this.setState({awaitingStatusChange: false}, async () => {
                        await this.fetchIncident();
                    })
                },
                undefined
            );
        });
    }


    /*     Data Renderer      */

    expandColumnSource: expandSourcesHandler = (sourcesPopoverAnchor: HTMLElement, sourcesPopoverData: ISourcesPopoverData) => {
        this.setState((current) => ({
            ...current,
            fieldSourcesPopoverAnchor: sourcesPopoverAnchor,
            fieldSourcesPopoverData: sourcesPopoverData
        }))
    }


    private editData = (data: any, field: string | null) => {
        const incident = this.state.incident;
        if (incident) {
            incident.data = data;
            if (field) {
                if (!incident.edited_fields) {
                    incident.edited_fields = [];
                }
                if (!incident.edited_fields.includes(field)) {
                    incident.edited_fields.push(field)
                }
            }
            this.setState((current) => ({...current, incident}), () => {
                this.scheduleSave();
            })
        }
    };

    private editTitle = (title: string) => {
        const incident = this.state.incident;
        if (incident) {
            incident.title = substr(title, 0, Math.max(title.length, 500));
            this.setState((current) => ({...current, incident}), () => {
                this.scheduleSave();
            })
        }
    };

    private scheduleSave() {
        if (this.state.saveTimeout) {
            clearTimeout(this.state.saveTimeout);
        }
        const timeout: NodeJS.Timeout = setTimeout(async () => {
            clearTimeout(timeout);
            await this.saveData();
            this.setState((curr) => ({...curr, saveTimeout: null}))
        }, AUTO_SAVE_DELAY);
        this.setState((curr) => ({...curr, saveTimeout: timeout}));
    }

    private async saveData() {
        if (this.state.awaitingSave) {
            this.setState((curr) => ({...curr, scheduleReSave: true}))
            return;
        }
        const incident = this.state.incident;
        if (!incident) {
            return;
        }
        this.setState((current) => ({...current, awaitingSave: true}), async () => {
            await server.post(
                "incident/",
                {
                    "id": incident.id,
                    "data": incident.data,
                    "title": incident.title,
                    "edited_fields": incident.edited_fields
                }
            );
            const notification: IIncidentSaveNotification = {
                incidentId: incident.id,
                stateAfter: undefined, stateBefore: undefined,
                notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
                actionType: INCIDENT_ACTIONS.SAVE,
                title: "השינויים נשמרו"
            };
            this.props.notificationContext.addNotification(notification)
            this.setState((current) => ({
                ...current,
                awaitingSave: false,
            }), () => {
                if (this.state.scheduleReSave) {
                    this.setState((current) => ({...current, scheduleReSave: false}), () => {
                        this.saveData();
                    })
                }
            });
        });
    }

    getTitleAdornment() {
        const incident = this.state.incident;
        if (incident === null) {
            return <CircularProgress/>
        }
        const currStatus = incident.approved;
        return <Stack direction="row" spacing={1}>
            {!this.state.awaitingStatusChange ? <React.Fragment>
                <Tooltip title={
                    <Stack dir={"rtl"}>
                        <span>מקורות:</span>
                        {incident.sources?.map(s => {
                            let publicationDate = s.post_publication_date;
                            if (publicationDate) {
                                publicationDate = publicationDate.split("T")[0];
                            }
                            else{
                                publicationDate = "תאריך פרסום לא ידוע"
                            }
                            return <span key={s.id}>
                                {(s.website_title) + ", " + publicationDate}
                            </span>
                        })}
                    </Stack>
                } arrow placement={"bottom"}>
                    <IconButton
                        aria-label="sources"
                        color={"primary"}
                        onClick={(e) => {
                            this.setState((curr) => ({...curr, sourcesPopoverAnchor: (e.target as HTMLElement)}))
                        }}
                    >
                        <Badge
                            badgeContent={incident.sources?.length}
                            color={"success"}
                        >
                            <NewspaperIcon/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"מיזוג"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="merge"
                        onClick={() => {
                            this.setState((curr) => ({...curr, mergePopupOpen: true}))
                        }}
                    >
                        <CallMergeIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"לא רלוונטי"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="remove"
                        color={"error"}
                        disabled={this.state.awaitingSave}
                        onClick={async () => {
                            await this.setIncidentStatus(currStatus === "removed" ? "not approved" : "removed")
                        }}
                        sx={currStatus === "removed" ? {backgroundColor: "error.main", color: "#fff"} : {}}

                    >
                        <RemoveCircleIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"אישור"} arrow placement={"bottom"}>
                    <IconButton
                        aria-label="approve"
                        color={"success"}
                        disabled={this.state.awaitingSave}
                        onClick={async () => {
                            await this.setIncidentStatus(currStatus === "approved" ? "not approved" : "approved")
                        }}
                        sx={currStatus === "approved" ? {backgroundColor: "success.main", color: "#fff"} : {}}
                    >
                        <VerifiedIcon/>
                    </IconButton>
                </Tooltip>
            </React.Fragment> : <CircularProgress/>}
        </Stack>
    }

    openSourcePreview(
        source: IIncidentReport,
        defaultPos: { x: number, y: number },
    ) {
        const sourcePreviews: ISourcePreview[] = this.state.sourcePreviews.slice();
        sourcePreviews.push({source, defaultPos});
        this.setState((current) => ({...current, sourcePreviews}));
    }


    closeSourcePreview(previewIndex: number) {
        const sourcePreviews = this.state.sourcePreviews.slice();
        sourcePreviews.splice(previewIndex, 1);
        this.setState((current) => ({...current, sourcePreviews}));
    }

    private getIncidentCard() {
        const columns = this.state.columns;
        const incident = this.state.incident;
        const emptyDataIndicators = this.state.emptyDataIndicators
        const incidentSources: IIncidentReport[] = incident?.sources?.slice() || [];
        const incidentError = this.state.incidentError;
        const status = incident?.approved;
        const cardColor =
            status === "approved" ? "#00ff0030" :
                status === "removed" ? "#ff000030" :
                    status === "not approved" ? "#00000030" :
                        "#00000030";
        return <Paper style={{width: "50vw"}} className={"incident-card-outer"}>{
            incidentError ? <span>{incidentError}</span> : (incident && emptyDataIndicators && columns ?
                <React.Fragment>
                    <TextField
                        className={"incident-card-title"}
                        label="שם תקרית"
                        variant="filled"
                        value={incident.title}
                        onChange={(e) => {
                            this.editTitle(e.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                this.getTitleAdornment()
                            ),
                        }}
                        sx={{
                            backgroundColor: cardColor,
                            borderRadius: "5px 5px 0 0"
                        }}
                    />
                    <div className={"incident-card-inner"}>
                        <IncidentField
                            column={columns}
                            data={incident.data}
                            citation={null}
                            sources={incidentSources}
                            onEdit={this.editData}
                            onClick={null}
                            onSourcesClick={this.expandColumnSource}
                            emptyChecker={(v: any) => isEmpty(v, emptyDataIndicators)}
                        />
                    </div>
                </React.Fragment> :
                <div className={"incident-loader-wrap"}><CircularProgress/></div>)
        }</Paper>
    }

    async splitIncidentReport(incidentId: number, incidentReportId: number) {
        await splitIncidentReportWithIds(
            [incidentReportId],
            this.props.notificationContext,
            async () => {
                await this.fetchIncident();
            },
            undefined
        );
    }

    getSourcePopoverFieldTitleOverride(incident: IIncident, s: IIncidentReport, sourcesCount: number) {
        return <Stack
            gap={1}
            direction={"row"}
            alignItems={"center"}
        >
            <span>{s.website_title}</span>
            <Tooltip
                title={"לעיון"}
                arrow
                placement={"bottom"}
            >
                <a href={s.post_url} target={"_blank"} rel={"noreferrer"}>
                    <IconButton
                        aria-label="go to"
                        color={"primary"}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <OpenInNew fontSize="small"/>
                    </IconButton>
                </a>
            </Tooltip>
            <Tooltip
                title={"פתיחה בשולחן העבודה"}
                arrow
                placement={"bottom"}
            >
                <IconButton
                    aria-label="open"
                    color={"primary"}
                    onClick={(e) => {
                        this.openSourcePreview(
                            s,
                            {
                                x: e.clientX,
                                y: e.clientY
                            }
                        )
                        this.setState((current) => ({
                            ...current,
                            fieldSourcesPopoverAnchor: null, fieldSourcesPopoverData: null
                        }));
                        e.stopPropagation();
                    }}
                >
                    <WebAsset fontSize="small"/>
                </IconButton>
            </Tooltip>
            {
                sourcesCount > 1 ?
                    <Tooltip
                        title={"פיצול מקור לתקרית נפרדת"}
                        arrow
                        placement={"bottom"}
                    >
                        <IconButton
                            aria-label="open"
                            color={"primary"}
                            onClick={async (e) => {
                                await this.splitIncidentReport(incident.id, s.id);
                                this.setState((current) => ({
                                    ...current,
                                    fieldSourcesPopoverAnchor: null,
                                    fieldSourcesPopoverData: null,
                                    sourcesPopoverAnchor: null,
                                }));
                                e.stopPropagation();
                            }}
                        >
                            <CallSplit fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    : null
            }
        </Stack>
    }

    private getSourcesPopover() {
        const incident = this.state.incident;
        if (!incident) {
            return null;
        }
        const sourcesPopoverData = incident.sources;
        return <Popover
            open={!!this.state.sourcesPopoverAnchor}
            anchorEl={this.state.sourcesPopoverAnchor}
            onClose={() => {
                this.setState((current) => ({
                    ...current,
                    fieldSourcesPopoverAnchor: null,
                    fieldSourcesPopoverData: null,
                    sourcesPopoverAnchor: null,
                }))
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{maxHeight: "50vh", width: "50vw"}}
        >
            <div className={"sources-pop-over"}>{
                sourcesPopoverData ?
                    <Stack gap={1}>
                        {sourcesPopoverData.map((s: IIncidentReport, i: number) => {
                            const metadataColumn: IColumnText = {
                                column_type: "TEXT",
                                description: "",
                                key: "source metadata",
                                multi_line: false,
                                title: s.website_title,
                                translation: ""
                            }
                            let publicationDate = s.post_publication_date;
                            if (publicationDate) {
                                publicationDate = publicationDate.split("T")[0];
                            }
                            else{
                                publicationDate = "תאריך פרסום לא ידוע"
                            }
                            return <React.Fragment key={i}>
                                <IncidentField
                                    column={metadataColumn}
                                    data={publicationDate + ", " + (s.post_title || s.website_title)}
                                    citation={null}
                                    sources={null}
                                    onEdit={null}
                                    onClick={null}
                                    onSourcesClick={null}
                                    emptyChecker={(v: any) => isEmpty(v, this.state.emptyDataIndicators)}
                                    labelOverride={this.getSourcePopoverFieldTitleOverride(incident, s, sourcesPopoverData.length)}
                                />
                            </React.Fragment>
                        })}
                    </Stack>
                    : null
            }</div>
        </Popover>
    }

    private getFieldSourcesPopover() {
        const incident = this.state.incident;
        if (!incident) {
            return null
        }
        const fieldSourcesPopoverData = this.state.fieldSourcesPopoverData;
        return <Popover
            open={!!this.state.fieldSourcesPopoverAnchor}
            anchorEl={this.state.fieldSourcesPopoverAnchor}
            onClose={() => {
                this.setState((current) => ({
                    ...current,
                    fieldSourcesPopoverAnchor: null,
                    fieldSourcesPopoverData: null,
                    sourcesPopoverAnchor: null,
                }))
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{maxHeight: "50vh"}}
        >
            <div className={"sources-pop-over"}>{
                fieldSourcesPopoverData ?
                    <Stack gap={1}>
                        {fieldSourcesPopoverData.data.map((s, i: number) => {
                            const sColumn = fieldSourcesPopoverData?.column;
                            if (!sColumn) {
                                return null
                            }
                            return <React.Fragment key={i}>
                                <IncidentField
                                    column={sColumn}
                                    data={s.data}
                                    citation={null}
                                    sources={null}
                                    onEdit={null}
                                    onClick={(v) => {
                                        fieldSourcesPopoverData?.onSelect(v);
                                        this.setState((current) => ({
                                            ...current,
                                            fieldSourcesPopoverAnchor: null,
                                            fieldSourcesPopoverData: null,
                                            sourcesPopoverAnchor: null,
                                        }))
                                    }}
                                    onSourcesClick={null}
                                    emptyChecker={(v: any) => isEmpty(v, this.state.emptyDataIndicators)}
                                    labelOverride={this.getSourcePopoverFieldTitleOverride(incident, s, fieldSourcesPopoverData?.data.length || 0)}
                                />
                            </React.Fragment>
                        })}
                    </Stack>
                    : null
            }</div>
        </Popover>
    }

    private getSourcesWorkspace() {
        const sourcePreviews = this.state.sourcePreviews.slice();
        return <div className={"preview-workspace"}>
            {
                sourcePreviews.map((sp, i) => {
                    return <SourcePreview
                        key={sp.source.id}
                        source={sp.source}
                        defaultPos={sp.defaultPos}
                        onClose={() => {
                            this.closeSourcePreview(i)
                        }}
                    />
                })
            }
        </div>
    }

    getMergeModal = () => {
        const columns = this.state.columns;
        const emptyDataIndicators = this.state.emptyDataIndicators;
        const currIncidentId = this.state.incident?.id

        return <Modal
            open={this.state.mergePopupOpen}
            onClose={() => {
                this.setState((curr) => ({...curr, mergePopupOpen: false}));
            }}
        >
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "80vw",
                    height: "70vh",
                    overflow: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
                dir={"rtl"}
            >
                {columns !== null && emptyDataIndicators !== null && currIncidentId !== undefined ?
                    <AlignDir direction={"ltr"}>
                        <IncidentTable
                            columnSchema={columns}
                            emptyDataIndicators={emptyDataIndicators}
                            permanentlySelectedRows={[currIncidentId]}
                            onMerge={() => {
                                this.setState((curr) => ({...curr, mergePopupOpen: false}), async () => {
                                    await this.fetchIncident()
                                });
                            }}
                        />
                    </AlignDir> : <CircularProgress/>}

            </Box>
        </Modal>
    }

    render() {
        return <div>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                gap={2}
                divider={<Divider orientation="horizontal" flexItem/>}
            >
                <Stack direction={"row"} justifyContent={"center"} gap={2}>
                    {this.getIncidentCard()}
                </Stack>
                {this.getSuggestedMerges()}
            </Stack>
            {this.getSourcesPopover()}
            {this.getFieldSourcesPopover()}
            {this.getSourcesWorkspace()}
            {this.getMergeModal()}
        </div>
    }

    private getSuggestedMerges() {
        const columns = this.state.columns;
        const incident = this.state.incident;
        const currIncidentId = incident?.id || null;
        const emptyDataIndicators = this.state.emptyDataIndicators
        if (currIncidentId === null || !columns || !emptyDataIndicators) {
            return null
        }
        return <MergeSuggestions
            incidentId={currIncidentId}
            data={this.state.incident?.data}
            columns={columns}
            emptyDataIndicators={emptyDataIndicators}
            onMerge={
                async (incidentId: number) => {
                    await mergeIncidents(
                        [incidentId, currIncidentId], currIncidentId,
                        this.props.notificationContext,
                        async (mergeResult) => {
                            if(mergeResult.merged_into !== this.props.id){
                                this.props.goToIncidentPage(mergeResult.merged_into);
                            }
                            else {
                                return await this.fetchIncident()
                            }
                        },
                        () => {
                        }
                    );
                }
            }
            onDismiss={
                async (incidentId: number) => {
                    await dismissMerge(
                        [incidentId], currIncidentId,
                        this.props.notificationContext,
                        async () => {
                            return await this.fetchIncident()
                        },
                        () => {
                        }
                    );
                }
            }
        />
    }
}

export default withNotifications<IProps>(IncidentViewer);