import React from 'react'
import {List, ListItemButton} from "@mui/material";


interface IProps<Item extends {[key: string]: any}> {
    data: Item[],
    renderer: (item: Item) => React.ReactElement,
    id: string,
    multiple: boolean,
    selected: Item[],
    onSelect: (selected: Item[]) => void,
}



class ListPicker<Item extends {[key: string]: any}> extends React.Component<IProps<Item>, {}> {
    constructor(props: IProps<Item>) {
        super(props);
        this.state = {}
    }

    render() {
        return <React.Fragment>
            {
                this.props.data.length ?
                    <List>
                        {
                            this.props.data.map((e: Item) => {
                                const selectedIds = this.props.selected.map(x=>x[this.props.id])
                                return <ListItemButton
                                    key={e[this.props.id]}
                                    onClick={() => {
                                        const selected = this.props.selected;
                                        if (this.props.multiple) {
                                            if (selectedIds.includes(e[this.props.id])) {
                                                this.props.onSelect(selected.filter((s) => s[this.props.id] !== e[this.props.id]))
                                            } else {
                                                this.props.onSelect([...selected, e])
                                            }
                                        } else {
                                            this.props.onSelect([e])
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: selectedIds.includes(e[this.props.id]) ? "rgba(0,0,0,0.25)" : "transparent",
                                    }}
                                >
                                    {
                                        this.props.renderer(e)
                                    }
                                </ListItemButton>
                            })
                        }
                    </List> :
                    <p>No Articles Identified</p>
            }
        </React.Fragment>
    }
}

export default ListPicker;