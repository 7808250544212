import fetch, {Headers} from 'node-fetch';
import config from './config';
import PubSub from "pubsub-js";
import events from "../lib/events";
import cookie from "js-cookie";

const apiPath='api/';

// TODO: move to common server/client code folder
enum serverErrorAlerts {
	missingPermission,
	missingToken
}
export enum HTTP_METHODS {
	post,
	get,
	delete,
	put,
	options,
	head,
	patch,
}

export interface IRequestOptions {
	ignoreErrors?: boolean,
}

export interface IErrorResponse {
	error: string
}

function get(path:string, options?: IRequestOptions){
	return post(path, {}, HTTP_METHODS.get, options)
}

const post = async (path:string, data:{[key:string]: any}, method?: HTTP_METHODS, options?: IRequestOptions):Promise<any> => {
	const fixedMethod = method === undefined ? HTTP_METHODS.post : method;
	const headers = new Headers();
	headers.set('Accept', 'application/json');
	headers.set('Content-Type', 'application/json');
	const token: string | undefined = cookie.get("token");
	if(token){
		headers.set("Authorization", "token:" + token)
	}
	const res = await fetch(config.serverPath+apiPath+path, {
		method: HTTP_METHODS[fixedMethod],
		body: (fixedMethod === HTTP_METHODS.get) ? undefined : JSON.stringify(data),
		headers,
	});
	const resAsJson = res.status === 401 ? {error: "missing permissions"} : await res.json();
	return handleResult(resAsJson, fixedMethod, path, data, options);
}

function handleResult(json:any, method:HTTP_METHODS, path:string, data?:{[key:string]: any}, options?: IRequestOptions){
	const currPosition = encodeURIComponent(window.location.pathname + window.location.search);
	return new Promise((resolve) => {
		let suppressResult = false;
		if (json && json.error && !(options && options.ignoreErrors)) {
			if (json.error === "missing token") {
				suppressResult = true;
				PubSub.publish(events.alert, alerts(serverErrorAlerts[serverErrorAlerts.missingToken],
					currPosition));
			} else if (json.error === "missing permissions") {
				suppressResult = true;
				PubSub.publish(events.alert, alerts(serverErrorAlerts[serverErrorAlerts.missingPermission],
					currPosition));
			}
		}
		if (!suppressResult) {
			resolve(json);
		}
	});
}

function alerts (type:string, path:string) {
	switch(type){
		case serverErrorAlerts[serverErrorAlerts.missingPermission]: return {
			content: "you don't have permissions to view this page, switch user?",
				flush: true,
				opaque: true,
				onClose: () => window.location.href = '/Login?redirect=' + path,
				resolutionOptions: [
				{
					label: "yes",
					onClick: () => window.location.href = '/Login?redirect=' + path,
				},
				{
					label: "no",
					onClick: () => window.location.href = '/Login',
				},
			]
		};
		case serverErrorAlerts[serverErrorAlerts.missingToken]: return {
			content: "you aren't logged in",
				flush: true,
				opaque: true,
				onClose: () => window.location.href = '/Login?redirect=' + path,
				resolutionOptions: [
				{
					label: "ok",
					onClick: () => window.location.href = '/Login?redirect=' + path,
				}
			]
		};
		default:
			break;
	}
}

const server = {
	get,
	post
}

export default (server)
