import React from 'react'
import {Box, Stack, Tab, Tabs} from "@mui/material";
import {IPost} from "../../services/entities/posts";
import QueryableExistingPostsPicker from "./QueryableExistingPostsPicker";
import PotentialPostsPicker from "./PotentialPostsPicker";
import {Dvr, EditNote, Unarchive} from "@mui/icons-material";


interface IProps {
    multiple: boolean,
    selected: IPost[],
    onSelect: (selected: IPost[]) => void,
}

interface IState {
    mode: "existing" | "potential" | "manual",
}


class ExtendedPostsPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            mode: "existing",
        }
    }

    render() {
        return <Stack
            direction={"column"} gap={1}
            sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100%",
                width: "100%"
            }}
        >
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={this.state.mode === "existing" ? 0 : 1}
                    onChange={(e, newValue) => {
                        this.setState((curr) => ({
                            ...curr,
                            mode: newValue === 0 ? "existing" : "potential"
                        }))
                    }}
                    aria-label="basic tabs example"
                >
                    <Tab label="Existing Posts" icon={<Unarchive/>} iconPosition="start"/>
                    <Tab label="Select From Sources" icon={<Dvr/>} iconPosition="start"/>
                    <Tab label="Manual Entry" icon={<EditNote/>} iconPosition="start"/>
                </Tabs>
            </Box>
            {
                this.state.mode === "existing" &&
                <QueryableExistingPostsPicker
                    multiple={this.props.multiple}
                    selected={this.props.selected}
                    onSelect={this.props.onSelect}
                />
            }
            {
                this.state.mode === "potential" &&
                <PotentialPostsPicker
                    multiple={this.props.multiple}
                    selected={this.props.selected}
                    onSelect={this.props.onSelect}
                />
            }
            {
                this.state.mode === "manual" &&
                <PotentialPostsPicker
                    multiple={this.props.multiple}
                    selected={this.props.selected}
                    onSelect={this.props.onSelect}
                />
            }
        </Stack>
    }
}

export default ExtendedPostsPicker;