import React from 'react';
import EntitiesEditor from './EntitiesEditor';
import {Button, CircularProgress, Stack} from "@mui/material";
import {Pagination} from "@mui/lab";
import withNotification, {WithNotificationProps} from "../../UIComponents/IncidentNotifications/withNotifications";
import {DataItem, DataQuery} from "../../services/entities/entityFields";
import {Entity} from "../../services/entities/entityBase";
import {NOTIFICATION_TYPE} from "../../UIComponents/IncidentNotifications/NotificationsContext";

interface IProps<ItemType extends DataItem, QueryType extends DataQuery<ItemType>> extends WithNotificationProps {
    entity: Entity<ItemType, QueryType>;
    query: QueryType;
    setQuery: (query: QueryType) => any;
    readOnly?: boolean
}

interface IState<ItemType> {
    pageCount: number,
    data: ItemType[];
    deletedRows: number[];
    awaitingFetch: boolean;
    awaitingSave: boolean;
}

export const defaultQuery: DataQuery<DataItem> = {
    columnFilterFns: {},
    columnFilters: [],
    globalFilter: "",
    pagination: {pageIndex: 0, pageSize: 50},
    sorting: []
}

class EntitiesManager<ItemType extends DataItem, QueryType extends DataQuery<ItemType>>
    extends React.Component<IProps<ItemType, QueryType>, IState<ItemType>> {

    constructor(props: IProps<ItemType, QueryType>) {
        super(props);
        this.state = {
            pageCount: 0,
            data: [],
            deletedRows: [],
            awaitingFetch: false,
            awaitingSave: false
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: IProps<ItemType, QueryType>) {
        if (this.props.entity !== prevProps.entity) {
            this.setState({
                data: [],
                deletedRows: [],
                awaitingSave: false
            }, async () => {
                await this.fetchData();
            });
        }
        if (JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query)) {
            this.setState({
                data: [],
                deletedRows: [],
                awaitingSave: false
            }, async () => {
                await this.fetchData();
            });
        }
    }

    private async fetchData() {
        if (this.state.awaitingFetch) {
            return
        }
        this.setState((curr) => ({...curr, awaitingFetch: true}), async () => {
            const entity = this.props.entity;
            const res = await entity.query({...defaultQuery, ...this.props.query});
            if (res && !("error" in res)) {
                const data = res.rows.map((x) => {
                    return {...x, _changed: false};
                });
                const pageCount = Math.ceil(res.rowCount / (
                    this.props.query?.pagination?.pageSize || defaultQuery.pagination.pageSize)
                )
                this.setState({data, pageCount, deletedRows: [], awaitingFetch: false});
            } else {
                this.setState({data: [], deletedRows: [], awaitingFetch: false});
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.ERROR,
                    actionType: null,
                    title: res?.error || "error - couldn't load data"
                })
            }
        });
    }

    private editData(row: number, field: string, value: any) {
        const data = this.state.data.slice();
        if (data.length < row || row < 0) {
            return;
        }
        const item = data[row] as any;
        item[field] = value;
        item._changed = true;
        this.setState({data});
    }

    private deleteData(rowIndex: number) {
        const data = this.state.data.slice();
        if (rowIndex < 0 || rowIndex >= data.length) {
            return;
        }
        const row = data[rowIndex];
        const rowId = row?.id;
        const deletedRows = this.state.deletedRows.slice();
        if (rowId && !(rowId in deletedRows)) {
            deletedRows.push(rowId);
        }
        data.splice(rowIndex, 1);
        this.setState({deletedRows, data});
    }

    private async saveData() {
        if (this.state.awaitingSave) {
            return;
        }
        this.setState({awaitingSave: true}, async () => {
            const entity = this.props.entity;
            const data = this.state.data.slice().filter(x => x._changed);
            const deletedRows = this.state.deletedRows.slice();
            const res = await entity.save(data, deletedRows);
            if (res && !("error" in res)) {
                this.setState({awaitingSave: false});
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.SAVE,
                    actionType: null,
                    title: "data saved"
                })
                await this.fetchData();
            } else {
                this.setState({awaitingSave: false});
                this.props.notificationContext.addNotification({
                    notificationType: NOTIFICATION_TYPE.ERROR,
                    actionType: null,
                    title: res?.error || "error - couldn't load data"
                })
            }
        });
    }

    render() {
        const {data, awaitingSave} = this.state;
        const pageCount = this.state.pageCount;

        return (
            <Stack direction={"column"} gap={1} className={"settings-editor"}>
                {
                    (!this.state.awaitingFetch) ? <>
                        <EntitiesEditor
                            entity={this.props.entity}
                            data={data}
                            editData={this.editData.bind(this)}
                            deleteData={this.deleteData.bind(this)}
                            readOnly={this.props.readOnly}
                        />
                        <Pagination
                            dir={"ltr"}
                            count={pageCount}
                            page={(this.props?.query?.pagination?.pageIndex || 0) + 1}
                            onChange={(_, page) => {
                                this.props.setQuery({
                                    ...this.props.query,
                                    pagination: {
                                        ...this.props.query.pagination,
                                        pageIndex: page - 1
                                    }
                                });
                            }}
                        />
                    </> : <CircularProgress/>
                }
                {
                    (!this.props.readOnly) && <div className={"save-section-wrap"}>
                        {awaitingSave ? <CircularProgress/> : (
                            <Button
                                onClick={async () => {
                                    await this.saveData();
                                }}
                                variant={"outlined"}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                }
            </Stack>
        );
    }
}

export default withNotification(EntitiesManager);