import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, Input, Slider,
    Stack, Tooltip,
} from "@mui/material";
import server from "../../services/server";
import {IIncident} from "../../services/types/incident";
import {isEmpty} from "./dataTreatment";
import {IColumn, TColumnTypes} from "../../services/types/columns";
import IncidentInfoCard from "./IncidentInfoCard";
import AlignDir from "../../services/languages/AlignDir";
import {
    ArrowDropUp, ArrowRight,
    ArrowUpward,
    CellTower,
    ContentCopy,
    Done,
    InsertEmoticon,
    Link,
    Refresh,
    TipsAndUpdatesTwoTone
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {LoadingButton} from "@mui/lab";
import Paper from "@mui/material/Paper";
import {incidentStatusProperties} from "../../services/types/incidentStatus";

interface IProps {
    incidentId: number,
    data: any,
    columns: IColumn,
    emptyDataIndicators: string[],
    onMerge: (incidentId: number) => Promise<any>,
    onDismiss: (incidentId: number) => Promise<any>,
}

enum mergeStatus {DEFAULT, MERGING, DISMISSING}

interface IIncidentIndex {
    property_path: string,
    property_value: any,
    id: number,
    incident_id: number,
    incident_report_id: number | null,
    custom_weight: number | null,
    default_weight: number | null,
    field_type: TColumnTypes
}

interface IState {
    similar: IIncident[] | null,
    loadingSimilar: boolean,
    similarityRequirement: number,
    awaitingMerge: { [key: number]: mergeStatus },
    indices: IIncidentIndex[] | null,
}

class MergeSuggestions extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            similar: null,
            loadingSimilar: false,
            similarityRequirement: 0.7,
            awaitingMerge: {},
            indices: null
        }
    }

    async componentDidMount() {
        await this.fetchSimilar()
        await this.fetchIndices()
    }

    async componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.incidentId !== prevProps.incidentId) {
            this.setState((curr) => ({...curr, similar: null, awaitingMerge: {}}), async () => {
                await this.fetchSimilar()
                await this.fetchIndices()
            })
        }
    }

    private async fetchSimilar() {
        if (this.state.loadingSimilar) {
            return
        }
        this.setState((curr) => ({...curr, loadingSimilar: true}), async () => {
            const res = await server.post(
                "incident/similar/",
                {
                    "id": this.props.incidentId,
                    "data": this.props.data,
                    "standard": this.state.similarityRequirement
                }
            );
            const similar = res.map((x: any) => {
                x.data = JSON.parse(x.data)
                return x;
            }).sort((a: any, b: any) => {
                return b.total_score - a.total_score
            })
            this.setState((curr) => ({...curr, similar, loadingSimilar: false}))
        })
    }

    private async fetchIndices() {
        const indices = await server.get(
            "incident/indices/" + this.props.incidentId
        );
        this.setState({indices})
    }

    private async autoRegenerateIndices() {
        const res = await server.post(
            "incident/indices/" + this.props.incidentId,
            {}
        );
        await this.fetchIndices();
    }

    private async mergeSuggestion(id: number) {
        const ongoingMerges = this.state.awaitingMerge;
        if (ongoingMerges[id]) {
            return
        }
        ongoingMerges[id] = mergeStatus.MERGING;
        this.setState((curr) => ({...curr, awaitingMerge: ongoingMerges}), async () => {
            await this.props.onMerge(id);
            const ongoingMerges = this.state.awaitingMerge;
            ongoingMerges[id] = mergeStatus.DEFAULT;
            this.setState((curr) => ({...curr, awaitingMerge: ongoingMerges}), async () => {
                await this.fetchSimilar()
            })
        })
    }

    private async dismissSuggestion(id: number) {
        const ongoingMerges = this.state.awaitingMerge;
        if (ongoingMerges[id]) {
            return
        }
        ongoingMerges[id] = mergeStatus.DISMISSING;
        this.setState((curr) => ({...curr, awaitingMerge: ongoingMerges}), async () => {
            await this.props.onDismiss(id);
            const ongoingMerges = this.state.awaitingMerge;
            ongoingMerges[id] = mergeStatus.DEFAULT;
            this.setState((curr) => ({...curr, awaitingMerge: ongoingMerges}), async () => {
                await this.fetchSimilar()
            })
        })
    }

    render() {
        const suggestions = this.state.similar?.slice() || null;
        const indices = this.state.indices?.slice() || null;
        return <div>
            <Stack
                direction={"row"}
                gap={2}
                alignItems={"center"}
            >
                <TipsAndUpdatesTwoTone/>
                <div className="title-wrap">
                    הצעות למיזוג
                </div>
                <FormControl>
                    <FormControlLabel
                        sx={{
                            alignItems: 'flex-start',
                            '& .MuiFormControlLabel-label': {
                                textAlign: 'start',
                            },
                        }}
                        labelPlacement={"top"}
                        control={
                            <Input
                                value={this.state.similarityRequirement}
                                size="small"
                                onChange={(e) => {
                                    this.setState((curr) => ({
                                        ...curr, similarityRequirement: parseFloat(e.target.value)
                                    }), async () => {
                                        await this.fetchSimilar()
                                    })
                                }}
                                inputProps={{
                                    step: 0.05,
                                    min: 0.5,
                                    max: 1,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        }
                        label={
                            <Stack direction={"row"} gap={1}>
                                <span>רף דמיון</span>
                                <Tooltip
                                    title="Adjust this value to determine how similar an incident has to be to be included in the suggestions">
                                    <InfoIcon color={"primary"}/>
                                </Tooltip>
                            </Stack>
                        }
                    />
                </FormControl>
            </Stack>
            {
                this.state.loadingSimilar && !suggestions ?
                    <CircularProgress/> : null
            }
            {suggestions ?
                <Stack
                    direction={"row"}
                    gap={2}
                    justifyContent={"start"}
                    sx={{
                        width: "100%",
                        padding: "1em",
                        boxSizing: "border-box",
                        overflowX: "auto",
                    }}
                >
                    {
                        suggestions.map((x, i) => {
                            return <React.Fragment key={i}><AlignDir direction={"ltr"}>
                                <Card
                                    variant={"outlined"}
                                    sx={{
                                        width: "30vw",
                                        backgroundColor: "#007FFF22",
                                    }}
                                >
                                    <Stack
                                        direction={"column"}
                                    >
                                        <Paper
                                            color={"primary"}
                                            sx={{
                                                borderBottomRightRadius: 0,
                                                borderBottomLeftRadius: 0,
                                                padding: "0.5em 1em"
                                            }}
                                        >
                                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                <Tooltip title={incidentStatusProperties[x.approved].title} arrow>
                                                    {incidentStatusProperties[x.approved].icon}
                                                </Tooltip>
                                                <span>
                                                    {x.title || "incident #" + x.id}
                                                </span>
                                                <a
                                                    target={"_blank"}
                                                    href={`${window.location.origin}/incident/${x.id}`}
                                                >
                                                    <IconButton
                                                        color={"primary"}
                                                        size="small"
                                                        component="div"
                                                    >
                                                        {<Link/>}
                                                    </IconButton>
                                                </a>
                                            </Stack>
                                        </Paper>
                                        <div style={{
                                            height: "30vh",
                                            overflowY: "auto",
                                            padding: "1em",
                                            boxSizing: "border-box",
                                        }}>
                                            <IncidentInfoCard
                                                column={this.props.columns}
                                                data={x.data}
                                                emptyChecker={(v: any) => isEmpty(v, this.props.emptyDataIndicators)}
                                            />
                                        </div>
                                        <Stack direction={"row"}>
                                            <LoadingButton
                                                variant={"contained"}
                                                sx={{
                                                    width: "60%",
                                                    borderBottomRightRadius: 0,
                                                    borderTopLeftRadius: 0,
                                                    borderTopRightRadius: 0,
                                                }}
                                                onClick={async () => {
                                                    await this.mergeSuggestion(x.id);
                                                }}
                                                loading={this.state.awaitingMerge[x.id] === mergeStatus.MERGING}
                                            >
                                                Merge
                                            </LoadingButton>
                                            <LoadingButton
                                                color={"error"}
                                                variant={"contained"}
                                                sx={{
                                                    width: "40%",
                                                    borderBottomLeftRadius: 0,
                                                    borderTopLeftRadius: 0,
                                                    borderTopRightRadius: 0,
                                                }}
                                                onClick={async () => {
                                                    await this.dismissSuggestion(x.id);
                                                }}
                                                loading={this.state.awaitingMerge[x.id] === mergeStatus.DISMISSING}
                                            >
                                                Not The Same
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                </Card>
                            </AlignDir></React.Fragment>
                        })
                    }
                </Stack> : null
            }
            {
                suggestions && suggestions.length === 0 ?
                    <Stack
                        sx={{
                            height: "30vh",
                            textAlign: "center",
                            margin: "0 auto"
                        }}
                        alignContent={"center"}
                        justifyContent={"center"}
                    >
                        <Done
                            color={"primary"}
                            sx={{
                                width: "15vh",
                                height: "15vh",
                                textAlign: "center",
                                margin: "0 auto"
                            }}
                        />
                        <span style={{fontSize: "2em"}}>נראה טוב!</span>
                        <span style={{fontSize: "1.5em"}}>אין לנו הצעות למיזוג</span>
                    </Stack>
                    : null
            }
            {indices ?
            <Accordion>
                <AccordionSummary>
                    <Stack
                        direction={"row"}
                        gap={2}
                        alignItems={"center"}
                    >
                        <CellTower/>
                        <div className="title-wrap">
                            כללי מיזוג
                        </div>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        direction={"row"}
                        gap={2}
                        alignItems={"start"}
                    >
                    <Tooltip title={"Regenerate Indices"}>
                        <IconButton
                            color={"primary"}
                            onClick={
                                async () => {
                                    await this.autoRegenerateIndices()
                                }
                            }
                        >
                            <Refresh/>
                        </IconButton>
                    </Tooltip>
                        <Stack
                            direction={"column"}
                            gap={1}
                            alignItems={"start"}
                        >
                            <span>
                                המערכת מחשבת את "מדד הדמיון" בין התקרית הזו לבין כל תקרית אחרת במערכת, כדי למזג אוטומטית כפילויות ולמצוא המלצות למיזוג.
                            </span>
                            {
                                indices.sort((a, b)=>
                                    ((b.custom_weight || b.default_weight || 0) - (a.custom_weight || a.default_weight || 0)))
                                    .filter(a => (a.custom_weight || a.default_weight || 0) !== 0)
                                    .filter(a => !a.property_path.includes("_SOUNDEX"))
                                    .filter((a, i, arr) =>
                                        arr.findIndex(b =>
                                            b.property_path === a.property_path && b.property_value === a.property_value
                                        ) === i)
                                    .map((incidentIndex, i) => {
                                    return <Stack
                                        direction={"row"}
                                        gap={1}
                                        alignItems={"start"}
                                            key={i}
                                    >
                                        <ArrowDropUp style={
                                            {color: `rgba(0, 150, 255, ${Math.max(incidentIndex.custom_weight || incidentIndex.default_weight || 0, 0.3)})`}
                                        }/>
                                        <span>
                                            <span>{"למדד הדמיון בין התקרית הזו לבין כל תקרית אחרת שערך השדה"} </span>
                                            <span><Stack divider={<ArrowRight/>} alignItems={"center"}
                                                         direction={"row"} dir={"ltr"} sx={{display: "inline-flex"}}>
                                                {
                                                    incidentIndex.property_path.split(">")
                                                        .filter(x => x !== "$")
                                                        .map((x, j) => <b key={j}>{x}</b>)
                                                }
                                            </Stack> </span>
                                            <span>{"שלה הוא גם"} </span>
                                            <b>{incidentIndex.property_value} </b>
                                            <span>{"יתווסף"} </span>
                                            <b>{incidentIndex.custom_weight || incidentIndex.default_weight} </b>
                                        </span>
                                    </Stack>
                                })
                            }
                            <span>כל תקרית שמדד הדמיון בינה לבין התקרית הזו גבוה מ-<b>1</b> תמוזג אוטומטית לתוכה</span>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion> : null}
        </div>
    }
}

export default MergeSuggestions;