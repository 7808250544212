import {TColumnTypes, IColumn, IColumnText} from "../../services/types/columns";
import {isEmpty} from "../incident/dataTreatment";
import React, {FunctionComponent} from "react";
import {Tooltip} from "@mui/material";
import {DropdownOption, LiteralUnion, MRT_FilterOption} from "material-react-table";
import DateFilter from "./columnFilters/DateEqFilter";
import {IColRendererOptions} from "../../services/entities/entityFields";

export const incidentColRenderers: {
    [k in TColumnTypes]:
    (value: any, options: IColRendererOptions) => React.ReactElement
} =
    {
        "OBJECT": (value: Object, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : JSON.stringify(value)}
            </span>
        },
        "ARRAY": (value: any[], options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <Tooltip
                title={(value || []).map(v => JSON.stringify(v)).join("\n")}
            >
                <span
                    style={{fontWeight: options.bold ? "bold" : "normal"}}
                    className={isEmpty ? "empty-col-data" : ""}
                >
                    {isEmpty ? "-" : value?.length}
                </span>
            </Tooltip>
        },
        "TEXT": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                    {isEmpty ? "-" : value}
                </span>
        },
        "DATE": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "SELECT": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "CITY": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "NUMBER": (value: string | number | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
    };

export const incidentColFilters: {
    [k in TColumnTypes]: {
        filterVariant?: {
            [key: string]: "text" | "range" | "multi-select" | "date-range" | "checkbox" | "select" | "time" | "datetime" | "autocomplete" | "date" | "datetime-range" | "range-slider" | "time-range" | undefined | FunctionComponent<any>
        },
        columnFilterModeOptions: LiteralUnion<string & MRT_FilterOption>[] | null,
        filterSelectOptions?: DropdownOption[]
    }
} = {
    "TEXT": {
        columnFilterModeOptions: ["contains", "equals", "startsWith", "endsWith", "notEquals", "notEmpty"],
        filterVariant: {
            "contains": "text",
            "equals": "text",
            "startsWith": "text",
            "endsWith": "text",
            "notEquals": "text",
            "notEmpty": "text",
        }
    },
    "NUMBER": {
        columnFilterModeOptions: ["betweenInclusive", "equals"],
        filterVariant: {
            "equals": "range-slider",
            "betweenInclusive": "range",
        }
    },
    "SELECT": {
        columnFilterModeOptions: ["equals"],
        filterVariant: {
            "equals": "multi-select"
        }
    },
    "CITY": {
        columnFilterModeOptions: ["contains", "equals", "startsWith", "endsWith", "notEquals", "notEmpty"],
        filterVariant: {
            "contains": "text",
            "equals": "text",
            "startsWith": "text",
            "endsWith": "text",
            "notEquals": "text",
            "notEmpty": "text",
        }
    },
    "DATE": {
        columnFilterModeOptions: ["equals", "betweenInclusive"],
        filterVariant: {
            "equals": DateFilter,
            "betweenInclusive": "date-range",
        }
    },
    "OBJECT": {
        columnFilterModeOptions: ["contains", "equals", "startsWith", "endsWith", "notEquals", "notEmpty"],
        filterVariant: {
            "contains": "text",
            "equals": "text",
            "startsWith": "text",
            "endsWith": "text",
            "notEquals": "text",
            "notEmpty": "text",
        }
    },
    "ARRAY": {
        columnFilterModeOptions: ["contains", "greaterThanOrEqualTo", "lessThanOrEqualTo"],
        filterVariant: {
            "contains": "text",
            "greaterThanOrEqualTo": "text",
            "lessThanOrEqualTo": "text",
        }
    },
};

export const getColDefaultWidth = (p: IColumn) => {
    switch ((p.column_type as TColumnTypes)) {
        case "ARRAY":
            return 50;
        case "NUMBER":
        case "SELECT":
            return 100;
        case "DATE":
            return 130;
        case "CITY":
            return 200;
        case "TEXT":
            return (p as IColumnText).multi_line ? 500 : 150;
        case "OBJECT":
            return 150;
    }
    return 100;
}