import {DropdownOption, LiteralUnion, MRT_FilterFns, MRT_FilterOption} from "material-react-table";
import {IColumn} from "../types/columns";
import React, {FunctionComponent} from "react";
import DateFilter from "../../pages/incident_browser/columnFilters/DateEqFilter";
import stringWidth from 'string-width';


export type DataFieldType = "bool" | "str" | "number" | "select" | "datetime" | "json"

export type DataItem = { id?: number | null, _changed?: boolean }

export interface DataQuery<ItemType extends DataItem> {
    pagination: { pageIndex: number, pageSize: number },
    columnFilters: { id: (keyof ItemType & string), value: unknown }[],
    columnFilterFns: { [key in keyof ItemType]?: string | keyof typeof MRT_FilterFns },
    globalFilter: string,
    sorting: {
        desc: boolean
        id: (keyof ItemType & string)
    }[],
}

export interface IQueryResult<ItemType> {
    page: number;
    rowCount: number;
    rows: ItemType[];
}

export interface IDataFieldBase<ItemType> {
    title: string,
    key: string,
    field_type: DataFieldType,
    disabled?: boolean
    hideWhen?: (item: ItemType) => boolean
}

export interface IDataFieldBool<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "bool",
}

export interface IDataFieldStr<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "str",
    multi_line?: boolean
}

export interface IDataFieldNumber<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "number",
}

export interface IDataFieldSelect<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "select",
    options: { value: string, label: string }[]
}

export interface IDataFieldDatetime<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "datetime",
}

export interface IDataFieldJSON<ItemType> extends IDataFieldBase<ItemType> {
    field_type: "json",
    schema: IColumn
}

export type IDataField<ItemType> =
    IDataFieldBool<ItemType> |
    IDataFieldStr<ItemType> |
    IDataFieldNumber<ItemType> |
    IDataFieldSelect<ItemType> |
    IDataFieldDatetime<ItemType> |
    IDataFieldJSON<ItemType>


export const dataFieldFilters: {
    [k in DataFieldType]: {
        filterVariant?: {
            [key: string]: "text" | "range" | "multi-select" | "date-range" | "checkbox" | "select" | "time" | "datetime" | "autocomplete" | "date" | "datetime-range" | "range-slider" | "time-range" | undefined | FunctionComponent<any>
        },
        columnFilterModeOptions: LiteralUnion<string & MRT_FilterOption>[] | null,
        filterSelectOptions?: DropdownOption[]
    }
} = {
    "str": {
        columnFilterModeOptions: ["contains", "equals", "startsWith", "endsWith", "notEquals", "notEmpty"],
        filterVariant: {
            "contains": "text",
            "equals": "text",
            "startsWith": "text",
            "endsWith": "text",
            "notEquals": "text",
            "notEmpty": "text",
        }
    },
    "number": {
        columnFilterModeOptions: ["betweenInclusive", "equals"],
        filterVariant: {
            "equals": "range-slider",
            "betweenInclusive": "range",
        }
    },
    "select": {
        columnFilterModeOptions: ["equals"],
        filterVariant: {
            "equals": "multi-select"
        }
    },
    "datetime": {
        columnFilterModeOptions: ["equals", "betweenInclusive"],
        filterVariant: {
            "equals": DateFilter,
            "betweenInclusive": "date-range",
        }
    },
    "bool": {
        columnFilterModeOptions: ["equals", "betweenInclusive"],
        filterVariant: {
            "equals": "multi-select",
        }
    },
    "json": {
        columnFilterModeOptions: ["contains", "equals", "startsWith", "endsWith", "notEquals", "notEmpty"],
        filterVariant: {
            "contains": "text",
            "equals": "text",
            "startsWith": "text",
            "endsWith": "text",
            "notEquals": "text",
            "notEmpty": "text",
        }
    },
};

export interface IColRendererOptions {
    bold?: boolean,
    noWrap?: boolean,
    endAdornment?: React.ReactElement,
    emptyChecker?: (value: any) => boolean,
}

export const dataFieldRenderers: {
    [k in Exclude<DataFieldType, "json">]:
    (value: any, options: IColRendererOptions) => (React.ReactElement | null)
} =
    {
        "number": (value: string | number | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "str": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "select": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "datetime": (value: string | null | undefined, options) => {
            const isEmpty = options.emptyChecker && options.emptyChecker(value);
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : value}
            </span>
        },
        "bool": (value: boolean | null | undefined, options) => {
            const isEmpty = value === null || value === undefined;
            return <span
                style={{fontWeight: options.bold ? "bold" : "normal"}}
                className={isEmpty ? "empty-col-data" : ""}
            >
                {isEmpty ? "-" : (value ? "True" : "False")}
            </span>
        },
    };

export const getDataFieldDefaultWidth = (p: Exclude<IDataField<any>, IDataFieldJSON<any>>) => {
    let minWidth = 100;
    switch ((p.field_type as Exclude<DataFieldType, "json">)) {
        case "number":
        case "select":
        case "bool":
            minWidth = 100;
            break;
        case "datetime":
            minWidth = 130;
            break;
        case "str":
            minWidth = (p as IDataFieldStr<any>).multi_line ? 500 : 150;
            break;
    }
    return Math.max(minWidth, (stringWidth(p.title) + 15) * 7);
}