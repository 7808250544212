import server from "../../server";
import {incidentStatus} from "../../types/incidentStatus";
import {IColumn, IColumnSelect} from "../../types/columns";
import stringSimilarity from "string-similarity";
import {
    ErrorNotification,
    IIncidentMergeNotification,
    INCIDENT_ACTIONS, IIncidentSplitNotification, IIncidentStatusChangeNotification,
    NOTIFICATION_TYPE, NotificationContextType
} from "../../../UIComponents/IncidentNotifications/NotificationsContext";

export const getDistinctReportsCount = (data: any[], x: IColumn, emptyChecker: (v:any)=>boolean) => {
    const fixedData = data.map(v=>{
        if(v==="True"){return true}
        if(v==="False"){return false}
        return v
    }).map(v=>{
        if((typeof v === 'string' || v instanceof String)){
            const clean_v = v.toLowerCase().trim()
            if(x.column_type === "SELECT"){
                const optionalValues = (<IColumnSelect>x).options.map(o=>emptyChecker(o) ? "unknown" : (o||"").toLowerCase())
                return stringSimilarity.findBestMatch(("" + (clean_v || "")), optionalValues).bestMatch.target.toLowerCase();
            }
            return clean_v;
        }
        return v
    });
    const nonEmptyData = fixedData.filter((d)=>!emptyChecker(d));
    const distinctData = new Set(nonEmptyData)
    const distinctCount = distinctData.size;
    return distinctCount || 1;
}

export interface IFailedAction {
    error: string
}

export interface ISplitResult {
    split: {incident_report_id: number, origin_incident_id: number}[],
    split_into: number,
    split_action_id: number
}

export const splitIncidentReportWithIds = async (
    incidentReportIds: number[],
    notificationContext?: NotificationContextType,
    successCallback?: (res: ISplitResult) => any,
    errorCallback?: (res:IFailedAction) => any
) => {
    const splitRes = (<ISplitResult | IFailedAction>await server.post(
        "incident_report/split/",
        {incident_report_ids: incidentReportIds}
    ));
    if(!("error" in splitRes)) {
        const splitNotification: IIncidentSplitNotification = {
            actionType: INCIDENT_ACTIONS.SPLIT,
            notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
            data: splitRes
        };
        if(notificationContext) {
            notificationContext.addNotification(splitNotification)
        }
        if (successCallback) {
            successCallback(splitRes)
        }
    } else {
        const errorNotification: ErrorNotification = {
            actionType: null,
            title: splitRes.error,
            notificationType: NOTIFICATION_TYPE.ERROR
        };
        if(notificationContext) {
            notificationContext.addNotification(errorNotification);
        }
        if (errorCallback) {
            errorCallback(splitRes)
        }
    }
}

export interface IMergeResult {
    merged_into: number,
    merged: number[],
    merge_action_ids: number[]
}

export const mergeIncidents = async (
    incidentIds: number[],
    mergeInto: number | null,
    notificationContext?: NotificationContextType,
    successCallback?: (res: IMergeResult) => any,
    errorCallback?: (res: IFailedAction) => any,
    undoSuccessCallback?: (res: ISplitResult[]) => any,
    undoErrorCallback?: (res: IFailedAction) => any,
) => {
    const mergeRes = (<IMergeResult | IFailedAction>await server.post(
        "incident/merge/",
        {incident_ids: incidentIds, merge_into: mergeInto}
    ));
    if(!("error" in mergeRes)) {
        const mergeNotification: IIncidentMergeNotification = {
            notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
            actionType: INCIDENT_ACTIONS.MERGE,
            data: mergeRes,
            undoSuccessCallback,
            undoErrorCallback
        };
        if(notificationContext) {
            notificationContext.addNotification(mergeNotification)
        }
        if (successCallback) {
            successCallback(mergeRes)
        }
    } else {
        const errorNotification: ErrorNotification = {
            actionType: null,
            title: mergeRes.error,
            notificationType: NOTIFICATION_TYPE.ERROR
        };
        if(notificationContext) {
            notificationContext.addNotification(errorNotification);
        }
        if (errorCallback) {
            errorCallback(mergeRes)
        }
    }
}


export const undoMerges = async (
    merge_action_ids: number[],
    notificationContext?: NotificationContextType,
    successCallback?: (res: ISplitResult[]) => any,
    errorCallback?: (res:IFailedAction) => any
) => {
    const undoMergeRes = (
        <ISplitResult[] | IFailedAction>await
            server.post("incident/merge/undo/", {merge_action_ids})
    )
    if(!("error" in undoMergeRes)) {
        const splitNotifications: IIncidentSplitNotification[] = undoMergeRes.map((splitRes) => ({
            actionType: INCIDENT_ACTIONS.SPLIT,
            notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
            data: splitRes
        }));
        if(notificationContext) {
            splitNotifications.forEach((splitNotification) => {
                notificationContext.addNotification(splitNotification)
            });
        }
        if (successCallback) {
            successCallback(undoMergeRes)
        }
    } else {
        const errorNotification: ErrorNotification = {
            actionType: null,
            title: undoMergeRes.error,
            notificationType: NOTIFICATION_TYPE.ERROR
        };
        if(notificationContext) {
            notificationContext.addNotification(errorNotification);
        }
        if (errorCallback) {
            errorCallback(undoMergeRes)
        }
    }
}

export interface IMergeDismissalResult {
    distinct_pairs: number[][],
}

export const dismissMerge = async (
    incidentIds: number[],
    dismissFrom: number,
    notificationContext?: NotificationContextType,
    successCallback?: (res: IMergeDismissalResult) => any,
    errorCallback?: (res:IFailedAction) => any
) => {
    const mergeDismissalRes = (<IMergeDismissalResult | IFailedAction>await server.post(
        "incident/merge/dismiss/",
        {incident_ids: incidentIds, dismiss_from: dismissFrom}
    ));
    if(!("error" in mergeDismissalRes)) {
        if (successCallback) {
            successCallback(mergeDismissalRes)
        }
    } else {
        const errorNotification: ErrorNotification = {
            actionType: null,
            title: mergeDismissalRes.error,
            notificationType: NOTIFICATION_TYPE.ERROR
        };
        if(notificationContext) {
            notificationContext.addNotification(errorNotification);
        }
        if (errorCallback) {
            errorCallback(mergeDismissalRes)
        }
    }
}

export interface IStatusChangeResult {
    incident_ids: number[],
    prev_status: {[key: number]: incidentStatus},
    new_status: {[key: number]: incidentStatus}
}

export const setIncidentApprovalStatus = async (
    status: { [key: number]: incidentStatus },
    notificationContext?: NotificationContextType,
    successCallback?: (res: IStatusChangeResult) => any,
    errorCallback?: (res:IFailedAction) => any
) => {
    const statusChangeRes = (<IStatusChangeResult | IFailedAction>await server.post(
        "incident/status/",
        {"status": status}
    ));
    if(!("error" in statusChangeRes)) {
        const mergeNotification: IIncidentStatusChangeNotification = {
            notificationType: NOTIFICATION_TYPE.INCIDENT_ACTION,
            actionType: INCIDENT_ACTIONS.STATUS_CHANGE,
            data: statusChangeRes,
            onUndo: async(
                data: IStatusChangeResult
            ) => {
                if (successCallback) {
                    await successCallback(data)
                }
            }
        };
        if(notificationContext) {
            notificationContext.addNotification(mergeNotification)
        }
        if (successCallback) {
            successCallback(statusChangeRes)
        }
    } else {
        const errorNotification: ErrorNotification = {
            actionType: null,
            title: statusChangeRes.error,
            notificationType: NOTIFICATION_TYPE.ERROR
        };
        if(notificationContext) {
            notificationContext.addNotification(errorNotification);
        }
        if (errorCallback) {
            errorCallback(statusChangeRes)
        }
    }
}